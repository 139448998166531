import { Badge } from "@mantine/core";
import { ShieldBan, ShieldCheck } from "lucide-react";
import React from "react";

const IsVerifed = (props: { isVerifed: boolean }) => {
  const { isVerifed = true } = props;
  return isVerifed ? (
    <Badge variant="outline" color="blue" size="md" className="py-3">
      <span className="flex items-center">
        Ro'yxatdan o'tgan
        <ShieldCheck className="ml-1" size={15} color="#228BE6" />
      </span>
    </Badge>
  ) : (
    <Badge variant="outline" color="red" size="md" className="py-3">
      <span className="flex items-center">
        Ro'yxatdan o'tmagan
        <ShieldBan className="ml-1" size={15} color="red" />
      </span>
    </Badge>
  );
};

export default IsVerifed;
