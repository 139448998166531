import {
  Alert,
  Autocomplete,
  AutocompleteProps,
  Avatar,
  Blockquote,
  Box,
  Button,
  Checkbox,
  Code,
  Collapse,
  Combobox,
  Divider,
  Flex,
  Group,
  Input,
  InputBase,
  List,
  LoadingOverlay,
  Modal,
  Notification,
  Paper,
  ScrollArea,
  SegmentedControl,
  Select,
  Table,
  Text,
  TextInput,
  Textarea,
  useCombobox,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { CalendarDays, Grid2X2, Check, Plus, Minus, Info } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import {
  FormValues,
  elements,
  useFormSubmission,
  useTelegram,
} from "./imports";
import dayjs from "dayjs";
import { useDisclosure } from "@mantine/hooks";
import instance from "../../configs/axios";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

const usersData: Record<string, { image: string; email: string }> = {
  "Emily Johnson": {
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-7.png",
    email: "emily92@gmail.com",
  },
  "Ava Rodriguez": {
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png",
    email: "ava_rose@gmail.com",
  },
  "Olivia Chen": {
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-4.png",
    email: "livvy_globe@gmail.com",
  },
  "Ethan Barnes": {
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png",
    email: "ethan_explorer@gmail.com",
  },
  "Mason Taylor": {
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png",
    email: "mason_musician@gmail.com",
  },
};

const renderAutocompleteOption: AutocompleteProps["renderOption"] = ({
  option,
}) => (
  <Group gap="sm">
    <Avatar src={usersData[option.value].image} size={36} radius="xl" />
    <div>
      <Text size="sm">{option.value}</Text>
      <Text size="xs" opacity={0.5}>
        {usersData[option.value].email}
      </Text>
    </div>
  </Group>
);

const users: { fullname: string }[] = [
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
  {
    fullname: "Sarvar Khalmatov",
  },
];

const elementsUsers = [
  { position: 6, mass: "Sarvar Khalmatov", symbol: "C", name: "Carbon" },
  { position: 7, mass: "Sarvar Khalmatov", symbol: "N", name: "Nitrogen" },
  { position: 39, mass: "Sarvar Khalmatov", symbol: "Y", name: "Yttrium" },
  { position: 56, mass: "Sarvar Khalmatov", symbol: "Ba", name: "Barium" },
  { position: 58, mass: "Sarvar Khalmatov", symbol: "Ce", name: "Cerium" },
];

const Forms = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [opened, { open, close }] = useDisclosure(false);

  const { tg } = useTelegram();
  const [visibleLoader] = useDisclosure(true);
  const [pressedButtons, setPressedButtons] = useState(
    new Array(users.length).fill(false)
  );
  const toggleButton = (index: any) => {
    const newPressedButtons = [...pressedButtons];
    newPressedButtons[index] = !newPressedButtons[index];
    setPressedButtons(newPressedButtons);
  };
  const {
    setValueCom,
    valueCom,
    errorFromWhom,
    setErrorFromWhom,
    onSendData,
    formValues,
    onSubmit,
    handleGenerateTable,
    loadingTable,
    visibleTable,
    openedToggle,
    handlerPayInInst,
    payInInstallments,
    setPayInInstallments,
    buttonRef,
    handleButtonClick,
    qachondan,
    qachongacha,
    setQachondan,
    setQachongacha,
  } = useFormSubmission();
  // const [qachondan, setQachondan] = useState<Date | null>(null);
  // const [qachongacha, setQachongacha] = useState<Date | null>(null);
  const [value, setValue] = useState<Date | null>(null);
  // const form = useForm({
  //   initialValues: {
  //     fromWhom: "",
  //     howMuch: "",
  //     reason: "",
  //     qachon: qachondan,
  //     qachongacha: qachongacha,
  //     notification: false,
  //     payInInstallments: false,
  //   },
  //   validate: {
  //     fromWhom: (value) =>
  //       value.length < 2 ? "Name must have at least 2 letters" : null,
  //     howMuch: (value) =>
  //       value.length < 2 ? "Name must have at least 2 letters" : null,
  //   },
  // });
  const formBolibTolash = useForm({
    initialValues: {
      howMuch: "",
    },
  });

  const handleQachondan = (value: any) => {
    setQachondan(value);
    // formValues.setFieldValue("qachon", dayjs(value).format("DD-MM-YYYY"));
    formValues.setFieldValue("lendingDate", value);
  };
  const handleQachongacha = (value: any) => {
    setQachongacha(value);
    // formValues.setFieldValue("qachongacha", dayjs(value).format("DD-MM-YYYY"));
    formValues.setFieldValue("returnDate", value);
  };

  useEffect(() => {
    tg.MainButton.setParams({
      text: "YARATISH",
    });
    tg.MainButton.show();
  }, []);

  useEffect(() => {
    opened ? tg.MainButton.hide() : tg.MainButton.show();
  }, [opened]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", handleButtonClick);
    return () => {
      tg.offEvent("mainButtonClicked", handleButtonClick);
    };
  }, [onSendData]);

  const rows = elements.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td className="text-center">{element.date}</Table.Td>
      <Table.Td className="text-center">{element.price}</Table.Td>
    </Table.Tr>
  ));

  const rowsUsers = elementsUsers.map((element: any) => (
    <Table.Tr
      key={element.name}
      bg={
        selectedRows.includes(element.position)
          ? "var(--mantine-color-blue-light)"
          : undefined
      }
    >
      {/* <Table.Td>{element.position}</Table.Td> */}
      {/* <Table.Td>{element.name}</Table.Td> */}
      {/* <Table.Td>
        <Avatar alt="it's me" />
      </Table.Td> */}
      <Table.Td>{element.mass}</Table.Td>
      <Table.Td className="text-center">
        <span>
          <Checkbox
            className="w-[100%]"
            aria-label="Select row"
            checked={selectedRows.includes(element.position)}
            onChange={(event) =>
              setSelectedRows(
                event.currentTarget.checked
                  ? [...selectedRows, element.position]
                  : selectedRows.filter(
                      (position) => position !== element.position
                    )
              )
            }
          />
        </span>
      </Table.Td>
    </Table.Tr>
  ));

  const [inputValue, setInputValue] = useState("");
  const formatInput = (value: any) => {
    // Remove non-digit characters from the input
    const numericValue = value.replace(/\D/g, "");

    // Format the numeric value with commas after every three numbers
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    formValues.setFieldValue("howMuch", formattedValue);
    // setInputValue(formattedValue);
  };

  const handlerQancha = (event: any) => {
    formatInput(event.target.value);
  };

  // ================== Combox ====================
  const [groceries, setMyFriends] = useState<
    {
      id: number;
      name: string;
      username: string;
      pathFile: string;
    }[]
  >([]);
  const { currentUserId } = useParams();

  // const groceries = [
  //   {
  //     fullName: "Abduqayum 1",
  //     id: 1,
  //   },
  //   {
  //     fullName: "Abduqayum 2",
  //     id: 2,
  //   },
  //   {
  //     fullName: "Abduqayum 3",
  //     id: 3,
  //   },
  //   {
  //     fullName: "Abduqayum 4",
  //     id: 4,
  //   },
  // ];

  useEffect(() => {
    (async () => {
      try {
        const res: AxiosResponse = await instance("/friend/hint", {});
        const data = res?.data?.data;
        setMyFriends(data);
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error);
      }
    })();
  }, []);

  const [search, setSearch] = useState("");
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  // console.log("🚀 ~ Forms ~ groceries:", groceries);
  const options = groceries
    ?.filter((item) =>
      item?.name?.toLowerCase()?.includes(search.toLowerCase().trim())
    )
    .map((item) => (
      <Combobox.Option
        id={String(item.id)}
        value={String(item.name)}
        key={item.id}
      >
        <Flex align={"center"}>
          <Avatar
            className="mr-2"
            src={
              item?.pathFile
                ? `${"https://qarzim.uz/api"}/storage/files/${item.pathFile}`
                : ""
            }
          />
          <Box>
            <Text fz="xs" fw={500}>
              {item.name}
            </Text>
            <Text fz="xs" opacity={0.6}>
              @{item.username}
            </Text>
          </Box>
        </Flex>
        {/* <Divider className="mt-2" /> */}
      </Combobox.Option>
    ));

  return (
    <Fragment>
      {/* <LoadingOverlay
        visible={visibleLoader}
        zIndex={1000}
        overlayProps={{ blur: 2 }}
        loaderProps={{ color: "blue", type: "bars" }}
      /> */}
      <Modal
        scrollAreaComponent={ScrollArea.Autosize}
        transitionProps={{ transition: "slide-left" }}
        centered
        opened={opened}
        onClose={close}
        title="Kimdan qarz oldingiz"
      >
        <Blockquote
          color="blue"
          cite={
            <div>
              <List>
                <List.Item>
                  Kimdan qarz oldingiz (bir nechta odamni tanlashingiz mumkin)
                </List.Item>
                <List.Item>
                  Siz qarz olganingiz haqidagi ma'lumotni to'liq to'ldirib
                  yaratganingizdan keyin quyidagi jadvalda tanlangan
                  do'stlaringizga so'rov boradi. Agar ular xaqiqatdan ham siz
                  undan qarz olganingiz haqidagi ma'lumotni tasdiqlasa ikkala
                  tarafni "Hisobotlarim" bo'limiga avtomatik tarzda qo'shiladi
                </List.Item>
              </List>
            </div>
          }
          icon={<Info />}
          mt="sm"
        >
          Eslatma:
        </Blockquote>

        <Paper className="mt-5" shadow="xs" withBorder pt="md">
          <div className="relative flex flex-col   rounded-xl bg-clip-border">
            <Text className="font-medium text-center">Mening do'stlarim</Text>

            <nav className="flex min-w-[240px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700">
              <div
                role="button"
                className="flex justify-between items-center w-full p-3 leading-tight transition-all rounded-lg outline-none text-start bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50 bg-opacity-80 text-blue-gray-900"
              >
                <div className="flex items-center">
                  <div className="grid mr-4 place-items-center">
                    <img
                      alt="alexander"
                      src="https://docs.material-tailwind.com/img/face-2.jpg"
                      className="relative inline-block h-12 w-12 !rounded-full  object-cover object-center"
                    />
                  </div>
                  <div>
                    <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                      Alexander
                    </h6>
                  </div>
                </div>
                <div>
                  <Checkbox />
                </div>
              </div>
            </nav>
          </div>
        </Paper>
        {/* <Alert
          variant="light"
          color="blue"
          title="Sizning do'stlaringiz"
          icon={<Info />}
        >
          Kimdan qarz oldingiz (bir nechta odamni tanlashingiz mumkin) Siz qarz
          olganingiz haqidagi ma'lumotni to'liq to'ldirib yaratganingizdan keyin
          quyidagi jadvalda tanlangan do'stlaringizga so'rov boradi. Agar ular
          xaqiqatdan ham siz undan qarz olganingizni tasdiqlasa keyin
          "Hisobotlarim" bo'limiga qo'shiladi
        </Alert> */}

        {/* <Table
          className="mt-5"

          highlightOnHover
          withTableBorder
          withColumnBorders
        >
          <Table.Thead>
            <Table.Tr>
              <Table.Th colSpan={3}>
                <div className="text-center">Mening do'stlarim</div>
              </Table.Th>
            </Table.Tr>
            <Table.Tr>
            
              <Table.Th>Fullname</Table.Th>
              <Table.Th>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rowsUsers}</Table.Tbody>
        </Table> */}
        {/* <List spacing="xs" size="md" className="w-[100%]">
          {users.map((user, index) => (
            <List.Item
              key={index}
              icon={<Avatar alt="it's me" />}
              style={{ width: "100% !important" }}
            >
              <span className="font-medium">{user.fullname}</span>

              {pressedButtons[index] ? (
                <Button
                  onClick={() => toggleButton(index)}
                  variant={pressedButtons[index] ? "outline" : "filled"}
                  rightSection={<Minus className="w-5 h-5" />}
                >
                  Olish
                </Button>
              ) : (
                <Button
                  onClick={() => toggleButton(index)}
                  variant={"light"}
                  rightSection={<Plus className="w-5 h-5" />}
                >
                  Tanlash
                </Button>
              )}
            </List.Item>
          ))}
        </List> */}
      </Modal>
      <Box className="text-left px-5 mb-4">
        <form
          onSubmit={formValues.onSubmit((values: FormValues) =>
            onSubmit(values)
          )}
        >
          <Combobox
            store={combobox}
            withinPortal={false}
            onOptionSubmit={(val, props: any) => {
              formValues.setFieldValue("toWhomId", props.id);
              setErrorFromWhom("");
              setValueCom(val);
              combobox.closeDropdown();
            }}
          >
            <Combobox.Target>
              <InputBase
                error={errorFromWhom}
                label={"Kimga"}
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron />}
                onClick={() => combobox.toggleDropdown()}
                rightSectionPointerEvents="none"
              >
                {valueCom || (
                  <Input.Placeholder>Do'st tanlang</Input.Placeholder>
                )}
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Search
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value)}
                placeholder="Do'stlar bo'limidan qidirish"
              />
              <Combobox.Options className="h-[300px] overflow-auto">
                {options.length > 0 ? (
                  options
                ) : (
                  <Combobox.Empty>Nothing found</Combobox.Empty>
                )}
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
          {/* <TextInput
            label="Kimdan"
            {...formValues.getInputProps("fromWhom")}
            placeholder="Kimdan qarz olganingizni kiriting"
          /> */}
          {/* <Autocomplete
            data={[
              "Emily Johnson",
              "Ava Rodriguez",
              "Olivia Chen",
              "Ethan Barnes",
              "Mason Taylor",
            ]}
            {...formValues.getInputProps("fromWhom")}
            renderOption={renderAutocompleteOption}
            maxDropdownHeight={300}
            label="Kimdan"
            placeholder="Kimdan qarz olganingizni kiriting"
          /> */}
          <TextInput
            label="Qancha"
            error={formValues.errors.howMuch}
            // type="number"
            value={formValues.values.howMuch}
            placeholder="Qancha"
            rightSection={<span className="pr-2">UZS</span>}
            mt="md"
            {...(formValues.getInputProps("howMuch"),
            {
              onChange: handlerQancha,
            })}
          />
          <Textarea
            maxLength={100}
            label="Sabab:"
            placeholder="Sabab"
            mt="md"
            {...formValues.getInputProps("reason")}
          />
          <Collapse in={!openedToggle} transitionTimingFunction="linear">
            <Flex
              gap="md"
              justify="space-between"
              align="flex-start"
              direction="row"
              className={`mt-3`}
            >
              <DatePickerInput
                clearable
                className="w-[50%]"
                label="Qachon berdingiz"
                placeholder="Sanani kiriting"
                error={formValues.errors.lendingDate}
                value={formValues.values.lendingDate}
                // onChange={handleQachondan}
                {...(formValues.getInputProps("lendingDate"),
                {
                  onChange: handleQachondan,
                })}
                leftSection={
                  <div className="flex items-center">
                    {/* <Calendar className="w-5 h-5 text-gray-400" /> */}
                    <CalendarDays className="w-5 h-5 text-gray-400" />
                  </div>
                }
              />
              <DatePickerInput
                clearable
                className="w-[50%]"
                label="Qachon olasiz"
                placeholder="Sanani kiriting"
                error={formValues.errors.returnDate}
                value={formValues.values.returnDate}
                // onChange={handleQachongacha}
                {...(formValues.getInputProps("returnDate"),
                {
                  onChange: handleQachongacha,
                })}
                leftSection={
                  <div className="flex items-center">
                    {/* <Calendar className="w-5 h-5 text-gray-400" /> */}
                    <CalendarDays className="w-5 h-5 text-gray-400" />
                  </div>
                }
              />
            </Flex>
          </Collapse>
          <Checkbox
            label="Eslatmani yoqish"
            description="Qarzingizni beradigan vaqtdan 1 kun oldin bot sizga avtomatik tarzda eslatadi"
            mt="md"
            {...formValues.getInputProps("onReminder", { type: "checkbox" })}
          />
          <Checkbox
            disabled
            label="Bo'lib to'lash"
            description={"Tez kunda..."}
            className="hidden"
            onClick={handlerPayInInst}
            mt="md"
            {...formValues.getInputProps("payInInstallments", {
              type: "checkbox",
            })}
          />

          <Button mt="sm" type="submit" ref={buttonRef} className="hidden">
            Submit
          </Button>
        </form>
        {/* <Button mt="sm" onClick={handleButtonClick}>
          Submit 2
        </Button> */}
      </Box>
      <Collapse in={openedToggle} transitionTimingFunction="linear">
        <Box className="text-left px-5">
          <SegmentedControl
            size="md"
            radius="xl"
            onChange={(e) => {
              setPayInInstallments(e === "Muddatga" ? 1 : 0);
            }}
            color="blue"
            fullWidth
            data={["Muddatga", "Miqdorga"]}
            className="mb-2"
          />

          {payInInstallments === 1 ? (
            <Box className="pb-5">
              <Flex
                gap="md"
                justify="space-between"
                align="flex-start"
                direction="row"
                className="mb-3"
              >
                <TextInput
                  type="number"
                  className="w-[50%]"
                  label="Qancha muddatga"
                  placeholder="Qancha muddatga qarz olyabsiz"
                />
                <Select
                  className="w-[50%]"
                  label="Qancha muddatga qarz olyabsiz"
                  placeholder="Kun, oy, yil"
                  data={["Kunga", "Oyga", "Yilga"]}
                />
                {/* <DatePickerInput
                  className="w-[50%]"
                  label="Qachon"
                  placeholder="Sanani kiriting"
                  value={value}
                  onChange={setValue}
                  rightSection={
                    <div className="flex items-center">
                      <CalendarDays className="w-5 h-5 text-gray-400" />
                    </div>
                  }
                />
                <DatePickerInput
                  className="w-[50%]"
                  label="Qachongacha"
                  placeholder="Sanani kiriting"
                  value={value}
                  onChange={setValue}
                  rightSection={
                    <div className="flex items-center">
                      <CalendarDays className="w-5 h-5 text-gray-400" />
                    </div>
                  }
                /> */}
              </Flex>
              <Flex justify="center" align="center" direction={"column"}>
                <Button
                  loading={loadingTable}
                  loaderProps={{ type: "dots" }}
                  leftSection={<Grid2X2 size={14} />}
                  variant="outline"
                  className="mb-3"
                  onClick={handleGenerateTable}
                >
                  Jadvalni {visibleTable ? "yangilash" : "ko’rish"}
                </Button>

                {visibleTable && (
                  <Table
                    striped
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                  >
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th className="text-center">Sana</Table.Th>
                        <Table.Th className="text-center">Narx</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {rows}
                      <Table.Tr key={500}>
                        <Table.Td colSpan={2}>
                          <div className="flex justify-between font-bold">
                            <span>Muddat:</span>
                            <span>13-oy</span>
                          </div>
                          <div className="flex justify-between font-bold">
                            <span>Birinchi to’lovgacha qoldi::</span>
                            <span>1-oy</span>
                          </div>
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                )}
              </Flex>
            </Box>
          ) : (
            <Box className="pb-5">
              <Flex
                gap="md"
                justify="space-between"
                align="flex-start"
                direction="row"
                className="mb-3"
              >
                <DatePickerInput
                  className="w-[50%]"
                  label="Qachon"
                  placeholder="Sanani kiriting"
                  value={value}
                  onChange={setValue}
                  rightSection={
                    <div className="flex items-center">
                      {/* <Calendar className="w-5 h-5 text-gray-400" /> */}
                      <CalendarDays className="w-5 h-5 text-gray-400" />
                    </div>
                  }
                />
                <TextInput
                  className="w-[50%]"
                  label="Qanchadan"
                  placeholder="200 000"
                  {...formBolibTolash.getInputProps("howMuchdan")}
                />
              </Flex>
              <Flex justify="center" align="center" direction={"column"}>
                <Button
                  loading={loadingTable}
                  loaderProps={{ type: "dots" }}
                  leftSection={<Grid2X2 size={14} />}
                  variant="outline"
                  className="mb-3"
                  onClick={handleGenerateTable}
                >
                  Jadvalni {visibleTable ? "yangilash" : "ko’rish"}
                </Button>

                {visibleTable && (
                  <Table
                    striped
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                  >
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th className="text-center">Sana</Table.Th>
                        <Table.Th className="text-center">Narx</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {rows}
                      <Table.Tr key={500}>
                        <Table.Td colSpan={2}>
                          <div className="flex justify-between font-bold">
                            <span>Muddat:</span>
                            <span>13-oy</span>
                          </div>
                          <div className="flex justify-between font-bold">
                            <span>Birinchi to’lovgacha qoldi::</span>
                            <span>1-oy</span>
                          </div>
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                )}
              </Flex>
            </Box>
          )}
        </Box>
      </Collapse>
      {/* <Box className="text-left px-5 pb-4">
        <Text size="sm" fw={500} mt="xl">
          Information sent to the bot from the web:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(formValues, null, 2)}
        </Code>
      </Box> */}
    </Fragment>
  );
};

const DefaultExport = {
  Forms,
};

export default DefaultExport;
