import { Flex, Text } from "@mantine/core";
import { Fragment } from "react";
import { FlagImage, usePhoneInput } from "react-international-phone";

interface PropsType {
  phoneNumber: string;
}

const PhoneViewer = (props: PropsType) => {
  const { phoneNumber } = props;

  const {
    inputValue,
    phone,
    country,
    setCountry,
    handlePhoneValueChange,
    inputRef,
  } = usePhoneInput({
    // forceDialCode: true,
    value: phoneNumber,
    onChange: ({ phone, inputValue, country }: any) => {
      // console.log("🚀 ~ MantinePhone ~ phone:", phone);
      // make something on change
    },
  });

  return (
    <Fragment>
      {/* <FlagImage className="mr-2" iso2={country.iso2} /> */}
      <Text fw={500}>{inputValue}</Text>
    </Fragment>
  );
};

export default PhoneViewer;
