interface Telegram {
  WebApp: {
    close(): void;
    ready(): void;
    MainButton: {
      isVisible: boolean;
      hide(): void;
      show(): void;
    };
    initDataUnsafe?: {
      user: any;
      query_id: any;
    };
  };
}

declare const window: {
  Telegram: Telegram;
};

export function useTelegram() {
  const tg: any = window.Telegram.WebApp;

  const onClose = () => {
    tg.close();
  };

  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };

  return {
    onClose,
    onToggleButton,
    tg,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
  };
}
