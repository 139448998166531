import {
  ActionIcon,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Flex,
  Group,
  Image,
  Modal,
  NumberInput,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { PhoneNumberUtil } from "google-libphonenumber";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  ArrowLeft,
  Check,
  Info,
  Phone,
  Plus,
  Trash2,
  UserPlus,
  UserRound,
} from "lucide-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../../configs/axios";
import { useChangeTrigger } from "../../utils";
import {
  PhoneInput,
  defaultCountries,
  usePhoneInput,
} from "react-international-phone";
import MantinePhone from "../MantinePhone";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const CreateFriendButton = () => {
  const [errorPhone, setErrorPhone] = useState("");

  const [phoneValue, setPhoneValue] = useState("");
  const phoneInputRef = useRef(null);
  // const isValidPhone = isPhoneValid(phone);

  const updateList = useChangeTrigger();
  const form = useForm({
    initialValues: {
      fullName: "",
      phone: "",
    },

    validate: {
      fullName: isNotEmpty("Name is required"),
      phone: (value) => {
        if (isPhoneValid(phoneValue)) {
          return null;
        } else {
          setErrorPhone("Phone number is not valid");
          return "asas";
        }
      },
    },
  });
  const { currentUserId } = useParams();
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);
  const [loadingAddToMyFr, setLoadingAddToMyFr] = useState(false);
  const [status, setStatus] = useState(0);
  const [existUser, setExistUser] = useState<{
    id: number;
    phoneNumber: number;
    friend: boolean;
    username: string;
    fullName: string;
    pathFile: string;
  } | null>();

  const addFriendApi = async () => {
    try {
      setLoading(true);
      const res = await instance("/friend/create", {
        method: "POST",
        data: {
          fullName: form.values.fullName,
          phoneNumber: phoneValue,
        },
      });

      if (res?.status === 200) {
        setStatus(2);
        notifications.show({
          icon: <Check style={{ width: rem(18), height: rem(18) }} />,
          color: "green",
          title: "Doʻst muvaffaqiyatli yaratildi",
          message: null,
        });
      }
    } catch (e) {
      const error = e as any;
      switch (error.response?.status) {
        case 409: {
          setExistUser(error?.response?.data?.data);
          setStatus(1);
          break;
        }
        case 408: {
          notifications.show({
            color: "yellow",
            message: "Joriy foydalanuvchi qo'shilmaydi!",
          });
          break;
        }

        default:
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    form.reset();
    setErrorPhone("");
    setStatus(0);
    setExistUser(null);
    close();
  };

  const handlerSendInvit = (type: boolean) => {
    if (type) {
      // API
    }
    close();
    setStatus(0);
  };

  const addToMyFriend = async () => {
    try {
      setLoadingAddToMyFr(true);
      const res = await instance("/friend/add", {
        method: "POST",
        params: {
          to_user_id: existUser?.id,
        },
      });

      setStatus(0);
      closeModal();
      updateList();
      notifications.show({
        icon: <Check style={{ width: rem(18), height: rem(18) }} />,
        color: "green",
        title: existUser?.fullName,
        message: `Do'stlaringiz safiga qo'shildi`,
      });
    } catch (error) {
      setLoadingAddToMyFr(false);
    } finally {
      setLoadingAddToMyFr(false);
    }
  };

  const onSubmit = (values: any) => {
    addFriendApi();
  };

  useEffect(() => {}, []);

  const handlerPhoneChange = (e: any) => {
    // console.log("🚀 ~ handlerPhoneChange ~ e:", country);
    // setPhone(phone)
  };

  return (
    <Fragment>
      <ActionIcon
        size={"xl"}
        variant="filled"
        radius="xl"
        onClick={open}
        aria-label="Settings"
        className="fixed bottom-5 right-5"
      >
        <Plus size={20} />
      </ActionIcon>

      <Modal.Root opened={opened} onClose={closeModal}>
        <Modal.Overlay backgroundOpacity={0.55} blur={3} />
        <Modal.Content>
          <Modal.Header>
            {status === 1 ? (
              <Flex
                className="cursor-pointer"
                align={"center"}
                onClick={() => {
                  setStatus(0);
                  form.reset();
                }}
              >
                <ArrowLeft className="mr-2" />
                Ortga qaytish
              </Flex>
            ) : (
              <Fragment>
                <Modal.Title>Do'st yaratish</Modal.Title>
                <Modal.CloseButton />
              </Fragment>
            )}
          </Modal.Header>
          <Modal.Body>
            {status === 0 ? (
              <Box>
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                  <Flex
                    gap="sm"
                    justify="flex-start"
                    align="flex-start"
                    direction="column"
                    wrap="wrap"
                  >
                    <TextInput
                      data-autofocus
                      className="w-full"
                      label="To'liq ism"
                      rightSection={<UserRound className="w-4 h-4" />}
                      rightSectionWidth={35}
                      placeholder="Iltimos, do'stingizni ismini kiriting"
                      {...form.getInputProps("fullName")}
                    />

                    {/* <NumberInput
                      type="tel"
                      className="mb-2 w-full"
                      {...form.getInputProps("phone")}
                      maxLength={9}
                      rightSection={<Phone className="w-4 h-4" />}
                      rightSectionWidth={35}
                      leftSection={<span> +998</span>}
                      label="Telefon raqami"
                      leftSectionWidth={50}
                    /> */}

                    <MantinePhone
                      errorPhone={errorPhone}
                      setErrorPhone={setErrorPhone}
                      form={form}
                      phoneValue={phoneValue}
                      setPhoneValue={setPhoneValue}
                    />
                    {/* <PhoneInput
                      // disableDialCodeAndPrefix={true}
                      // showDisabledDialCodeAndPrefix={true}
                      className="w-full"
                      // forceDialCode={true}
                      defaultCountry="uz"
                      value={inputValue}
                      disableFocusAfterCountrySelect
                      hideDropdown={true}
                      onChange={handlerPhoneChange}
                    /> */}
                  </Flex>

                  <Group justify="flex-end" mt="md">
                    <Button type="submit" className="w-full" loading={loading}>
                      Yaratish
                    </Button>
                  </Group>
                </form>
              </Box>
            ) : status === 1 ? (
              <Box>
                <Alert
                  variant="light"
                  color="blue"
                  // title="Ogohlantirish"
                  mb="md"
                  icon={<Info />}
                >
                  Ushbu foydalanuvchi allaqachon mavjud!
                </Alert>
                {/* <Box className="px-2">
                  <Blockquote
                    color="blue"
                    icon={<Info />}
                    my="md"
                    p={"lg"}
                  >
                    Ushbu foydalanuvchi allaqachon mavjud!
                  </Blockquote>
                </Box> */}
                <Card
                  shadow="sm"
                  padding="sm"
                  radius="md"
                  withBorder
                  className="mb-2"
                >
                  <Card.Section pt={"sm"}>
                    <Flex justify={"center"}>
                      <Avatar
                        className="shadow-md"
                        size={"xl"}
                        src={
                          existUser?.pathFile
                            ? `${"https://qarzim.uz/api"}/storage/files/${
                                existUser.pathFile
                              }`
                            : ""
                        }
                      />
                    </Flex>
                  </Card.Section>

                  <Box mt="sm">
                    <Group justify="space-between">
                      <Text size="sm" c="dimmed">
                        Ismi:
                      </Text>
                      <Text fw={500}>{existUser?.fullName}</Text>
                    </Group>
                    {existUser?.phoneNumber && (
                      <Group justify="space-between">
                        <Text size="sm" c="dimmed">
                          Telefon raqami:
                        </Text>
                        <Text fw={500}>+{existUser?.phoneNumber}</Text>
                      </Group>
                    )}
                    {existUser?.username && (
                      <Group justify="space-between">
                        <Text size="sm" c="dimmed">
                          Username:
                        </Text>
                        <Text fw={500} c="var(--mantine-color-anchor)">
                          @{existUser?.username}
                        </Text>
                      </Group>
                    )}
                  </Box>

                  {!existUser?.friend && (
                    <Button
                      color={existUser?.friend ? "red" : undefined}
                      variant="light"
                      loading={loadingAddToMyFr}
                      fullWidth
                      onClick={addToMyFriend}
                      mt="md"
                      radius="md"
                      leftSection={
                        !existUser?.friend ? (
                          <UserPlus size={14} />
                        ) : (
                          <Trash2 size={14} />
                        )
                      }
                    >
                      {!existUser?.friend
                        ? "Do'stlarimga qo'shish"
                        : "O'chirish"}
                    </Button>
                  )}
                </Card>
              </Box>
            ) : (
              <Box>
                <Text>
                  Yangi do'stingizga SMS taklifnoma yuborishni istaysizmi?
                </Text>
                {/* <Paper shadow="sm" radius="sm" withBorder p="sm">
                  <Text>Paper is the most basic ui component</Text>
                  <Text>
                    Use it to create cards, dropdowns, modals and other
                    components that require background with shadow
                  </Text>
                </Paper> */}
                <Box className="flex justify-end mt-2">
                  <Button
                    variant="default"
                    className="mr-2"
                    onClick={() => {
                      closeModal();
                      updateList();
                    }}
                  >
                    Yo'q, shart emas
                  </Button>
                  <Button
                    onClick={() => {
                      closeModal();
                      updateList();
                    }}
                  >
                    Xa, yuborilsin
                  </Button>
                </Box>
              </Box>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </Fragment>
  );
};

export default CreateFriendButton;
