import {
  Box,
  Button,
  Flex,
  Image,
  LoadingOverlay,
  Modal,
  Tabs,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { AxiosResponse } from "axios";
import { motion } from "framer-motion";
import { Check, Contact, SearchIcon, ServerOff, Users, X } from "lucide-react";
import { Fragment, useCallback, useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import CreateFriendButton from "../../components/CreateFriendButton";
import ImageViewerModal from "../../components/ImageViewer";
import ListMyFriends from "../../components/ListMyFriends";
import MySkelaton from "../../components/MySkelaton";
import NavTitle from "../../components/NavTitle";
import PostItemGlobal from "../../components/PostItemGlobal";
import ResultPaper from "../../components/ResultPaper";
import instance from "../../configs/axios";
import { Context } from "../../context";
import { useChangeTrigger } from "../../utils";
import { useTelegram } from "../Borrow/imports";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
export interface ResFriend {
  fullName: string;
  friendId: number;
  userId: number;
  phoneNumber: string;
  username: string;
  pathFile: string;
  friend: boolean;
}

export interface MyFriend {
  fullName: string;
  id: number;
  username: string;
  pathFile: string;
  userId: number;
  realUser: boolean;
  phoneNumber: string;
}

export interface TypeMyFriendsProps {
  value: string;
  error: string;
  errorRes: string;
  resultText: string;
  loading: boolean;
}

interface PagingTypes {
  page: number;
  size: number;
}

interface MetaTypes {
  page: number;
  size: number;
  totalCount: number;
  totalPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const Friends = () => {
  const changeTrigger = useChangeTrigger();
  const { dispatch, state } = useContext(Context);
  const { tg } = useTelegram();
  const { currentUserId } = useParams();
  const [opened, { open, close }] = useDisclosure(false);
  const [addingUser, setAddingUser] = useState<number | null>(null);
  const [triggerSearchMyFriend, setTriggerSearchMyFriend] = useState(false);
  const [myFriendProps, setMyFriendProps] = useState<TypeMyFriendsProps>({
    value: "",
    error: "",
    errorRes: "",
    resultText: "Hozircha do'stlaringiz yo'q",
    loading: false,
  });
  const [searchFriendProps, setSearchFriendProps] = useState({
    value: "",
    error: "",
    errorRes: "",
    resultText: "Hozircha maʼlumot yoʻq",
    loading: false,
  });
  const [searchResult, setSearchResult] = useState<ResFriend[]>([]);
  const [pagingMyFriends, setPagingMyFriends] = useState<PagingTypes>({
    page: 1,
    size: 5,
  });
  const [pagingFriends, setPagingFriends] = useState<PagingTypes>({
    page: 1,
    size: 5,
  });
  const [metaMyFriends, setMetaMyFriends] = useState<MetaTypes>({
    page: 0,
    size: 0,
    totalCount: 0,
    totalPage: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [metaFriends, setMetaFriends] = useState<MetaTypes>({
    page: 0,
    size: 0,
    totalCount: 0,
    totalPage: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [myFriends, setMyFriends] = useState<MyFriend[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [triggerTabs, setTriggerTabs] = useState<boolean>(false);
  const iconStyle = { width: rem(12), height: rem(12) };
  const [clickedUser, setClickedUser] = useState<ResFriend | MyFriend>();

  const onSendData = useCallback(() => {
    // Assuming tg is defined somewhere
    // tg.sendData(JSON.stringify(formValues));
    console.log("Sending data for telegram bot:", "asas");
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       setLoading(true);
  //       const res = await instance(`/friend`, {
  //         params: {
  //           current_user_id: currentUserId,
  //           page: pagingMyFriends.page,
  //           size: pagingMyFriends.size,
  //         },
  //       });
  //       const data: MyFriend[] = res?.data?.data?.items;
  //       const meta: MetaTypes = res?.data?.data?.meta;
  //       setMyFriends(data);
  //       setMetaMyFriends(meta);
  //     } catch (e) {
  //       const error = e as AxiosError;
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, [trigger, pagingMyFriends]);

  // useEffect(() => {
  //   tg.MainButton.setParams({
  //     text: "YARATISH",
  //   });
  //   tg.MainButton.show();
  // }, []);

  // useEffect(() => {
  //   tg.onEvent("mainButtonClicked", handleButtonClick);
  //   return () => {
  //     tg.offEvent("mainButtonClicked", handleButtonClick);
  //   };
  // }, [onSendData]);

  const handleButtonClick = () => {
    console.log("Clicked");
  };

  // Add to firends
  const handlerAddAndRemoveUser = async (user: ResFriend) => {
    const { userId, fullName, friend, friendId } = user;
    try {
      setAddingUser(userId);
      const res = await instance(
        `/friend/${friend ? `remove/${friendId}` : "add"}`,
        {
          method: friend ? "DELETE" : "POST",
          params: {
            ...(!friend && { to_user_id: userId }),
          },
        }
      );
      const myFriend: ResFriend = res?.data?.data;
      const updatedSearchResult = searchResult.map((user) => {
        if (user.userId === userId) {
          return {
            ...user,
            friend: !friend,
            ...(!friend && { friendId: myFriend.friendId }),
          };
        }
        return user;
      });

      setSearchResult(updatedSearchResult);
      changeTrigger();

      notifications.show({
        icon: <Check style={{ width: rem(18), height: rem(18) }} />,
        color: "green",
        title: fullName,
        message: friend
          ? `Do'stlaringiz safidan o'chirildi`
          : `Do'stlaringiz safiga qo'shildi`,
      });
    } catch (error: any) {
      notifications.show({
        color: "red",
        title: error.message,
        message: error.response?.data?.message,
        // classNames: classes,
      });
    } finally {
      setAddingUser(null);
    }
  };

  // Remove from friends
  const handlerRemoveUser = async (id: number, fullname: string) => {
    try {
      setAddingUser(id);
      const res = await instance(`/friend/remove/${id}`, {
        method: "DELETE",
      });

      notifications.show({
        icon: <Check style={{ width: rem(18), height: rem(18) }} />,
        color: "green",
        title: fullname,
        message: `Do'stlaringiz safidan o'chirildi`,
      });

      const filteredUsers = myFriends.filter((user) => user.id !== id);

      setMyFriends(filteredUsers);
      changeTrigger();
      if (!filteredUsers?.length && pagingMyFriends.page !== 1) {
        setPagingMyFriends((prevState) => ({
          ...prevState,
          page: pagingMyFriends.page - 1,
        }));
      }
    } catch (error) {
    } finally {
      setAddingUser(null);
    }
  };

  const openModalRemove = (id: number, fullName: string) =>
    modals.openConfirmModal({
      title: "Ogohlantirish",
      children: (
        <Text className="text-center" size="md">
          Bu doʻstingizni oʻchirib tashlamoqchimisiz?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handlerRemoveUser(id, fullName),
    });

  const onChangeSearchFriend = (e: any) => {
    const value: string = e?.target?.value;
    setSearchFriendProps((prevState) => ({
      ...prevState,
      value,
      error: "",
    }));
    if (!value) {
      setSearchFriendProps((prevState) => ({
        ...prevState,
        errorRes: "",
        resultText: "Hozircha maʼlumot yoʻq",
      }));
      setSearchResult([]);
      setMetaFriends({
        page: 0,
        size: 0,
        totalCount: 0,
        totalPage: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      });
      setPagingMyFriends({
        page: 1,
        size: 5,
      });
    }
  };

  const fetch = async (pageNumber: number = 1) => {
    try {
      const res: AxiosResponse = await instance("/friend/search", {
        params: {
          search: searchFriendProps.value,
          page: pageNumber,
          size: 5,
        },
      });
      const data: ResFriend[] = res?.data?.data?.items;
      const meta: MetaTypes = res?.data?.data?.meta;
      setMetaFriends(meta);

      if (data?.length) {
        setSearchResult((prevItems) => [...prevItems, ...data]);
        // setSearchResult(data);
        // setSearchResult((prevData: any) => [...prevData, data]);
      } else {
        setSearchFriendProps((prevState) => ({
          ...prevState,
          resultText: "Uzr, Bunaqa foydalanuvchi topilmadi",
        }));
      }
    } catch (error) {
    } finally {
      setPagingFriends((p) => ({
        ...p,
        page: p.page + 1,
      }));
      setSearchFriendProps((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const fetchMoreData = (page: number) => {
    return fetch(page);
  };
  const handlerClickSearchFriend = async () => {
    const trimmedStr = searchFriendProps.value.trim();

    if (trimmedStr) {
      setSearchFriendProps((prevState) => ({
        ...prevState,
        loading: true,
      }));
      setPagingFriends((p) => ({
        ...p,
        page: 1,
      }));
      setSearchResult([]);
      fetch(1);
      // try {
      //   setSearchFriendProps((prevState) => ({
      //     ...prevState,
      //     loading: true,
      //   }));
      //   const res: AxiosResponse = await instance("/friend/search", {
      //     params: {
      //       search: searchFriendProps.value,
      //       current_user_id: currentUserId,
      //       page: 1,
      //       size: 5,
      //     },
      //   });
      //   const data: ResFriend[] = res?.data?.data?.items;
      //   const meta: MetaTypes = res?.data?.data?.meta;
      //   setMetaFriends(meta);

      //   if (data?.length) {
      //     setSearchResult(data);
      //   } else {
      //     setSearchFriendProps((prevState) => ({
      //       ...prevState,
      //       resultText: "Uzr, Bunaqa foydalanuvchi topilmadi",
      //     }));
      //   }
      // } catch (e: unknown) {
      //   const error = e as AxiosError;
      //   setSearchFriendProps((prevState) => ({
      //     ...prevState,
      //     errorRes: error.message,
      //   }));
      // } finally {
      //   setSearchFriendProps((prevState) => ({
      //     ...prevState,
      //     loading: false,
      //   }));
      // }
    } else {
      setSearchFriendProps((prevState) => ({
        ...prevState,
        error: "Iltimos, qidirish maydonini toʻldiring",
      }));
    }
  };

  const clearSearchFriendValue = () => {
    setSearchFriendProps((prevState) => ({
      ...prevState,
      value: "",
      error: "",
      errorRes: "",
      resultText: "Hozircha maʼlumot yoʻq",
    }));
    setSearchResult([]);
    setMetaFriends({
      page: 0,
      size: 0,
      totalCount: 0,
      totalPage: 0,
      hasPreviousPage: false,
      hasNextPage: false,
    });
    setPagingMyFriends({
      page: 1,
      size: 5,
    });
  };

  const openUserProfile = (user: ResFriend | MyFriend) => {
    setClickedUser(user);
    open();
  };

  const handlerTabChange = (e: any) => {
    // setTriggerTabs((trigger) => !trigger);
    if (e === "myFriends") {
      changeTrigger();
    } else {
      clearSearchFriendValue();
    }
  };

  const handlerPagin = (page: number) => {
    console.log("metaMyFriends: ", metaMyFriends);

    setPagingMyFriends((prevState) => ({
      ...prevState,
      page,
    }));
  };
  const handlerPaginSearch = async (page: number) => {
    setPagingFriends((prevState) => ({
      ...prevState,
      page,
    }));

    try {
      const res: AxiosResponse = await instance("/friend/search", {
        params: {
          search: searchFriendProps.value,
          page,
          size: pagingFriends.size,
        },
      });
      const data: ResFriend[] = res?.data?.data?.items;
      const meta: MetaTypes = res?.data?.data?.meta;
      setMetaFriends(meta);

      setSearchResult(data);
    } catch (error) {}
  };

  const handleSearchMyFriend = () => {
    if (myFriendProps.value) {
      setTriggerSearchMyFriend((trigger) => !trigger);
    } else {
      setMyFriendProps((prevState) => ({
        ...prevState,
        error: "Iltimos, qidirish maydonini toʻldiring",
      }));
    }
  };

  const clearSearchMyFriendValue = () => {
    changeTrigger();
    setMyFriendProps((prevState) => ({
      ...prevState,
      value: "",
      error: "",
      errorRes: "",
      resultText: "Hozircha do'stlaringiz yoʻq",
    }));
  };

  const onChangeSearchMyFriend = (e: any) => {
    const value: string = e?.target?.value;
    setMyFriendProps((prevState) => ({
      ...prevState,
      value,
      error: "",
    }));

    if (!value) {
      // changeTrigger();
    }
  };

  return (
    <div>
      <NavTitle title="Do'stlarim" newMessage />

      <Box className="text-left mb-4">
        <Tabs
          variant="pills"
          radius="md"
          defaultValue="myFriends"
          onChange={(e) => handlerTabChange(e)}
        >
          <Tabs.List grow className="px-5 z-10 sticky top-[57px] myFixedBg">
            <Tabs.Tab
              className="shadow-md"
              value="myFriends"
              leftSection={<Contact style={iconStyle} />}
            >
              Mening do'stlarim
            </Tabs.Tab>
            <Tabs.Tab
              className="shadow-md"
              value="searchFriends"
              leftSection={<Users style={iconStyle} />}
            >
              Do'stlarni qidirish
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="myFriends">
            <Flex
              mih={50}
              gap="sm"
              justify="space-between"
              className="shadow-md px-5 py-4 sticky top-[91px] myFixedBg z-10"
            >
              {/* <TextInput
            label="Umumiy qidiruv maydoni"
            description="Iltimos do'stingizni ismini, usernameni yoki tel raqamini kiriting"
            placeholder="Sarvar, sarvar01, 998944186258"
            inputWrapperOrder={["label", "error", "input", "description"]}
            className="w-full"
            rightSection={<Button className="w-full">Qidirish</Button>}
            rightSectionProps={{ className: "w-[100px]" }}
          /> */}
              <TextInput
                disabled={myFriendProps.loading}
                value={myFriendProps.value}
                error={myFriendProps.error}
                onChange={onChangeSearchMyFriend}
                rightSectionPointerEvents="all"
                placeholder="Do'stingizni ismini kiriting"
                className="w-full"
                rightSection={
                  <X
                    aria-label="Clear input"
                    onClick={clearSearchMyFriendValue}
                    style={{
                      display: myFriendProps.value ? undefined : "none",
                    }}
                  />
                }
              />
              <Button variant="filled" onClick={handleSearchMyFriend}>
                <SearchIcon size={20} />
              </Button>
            </Flex>

            <Box className="mt-5 mx-5">
              {/* <Flex justify={"center"}>
                <Badge variant="outline" color="blue" size="lg">
                  Mening do'stlarim
                </Badge>
              </Flex> */}
              <ListMyFriends
                setMyFriendProps={setMyFriendProps}
                myFriendProps={myFriendProps}
                triggerSearch={triggerSearchMyFriend}
                currentUserId={currentUserId}
                triggerTabs={state.trigger}
              />
            </Box>

            <CreateFriendButton />
          </Tabs.Panel>

          <Tabs.Panel value="searchFriends">
            <Flex
              mih={50}
              gap="sm"
              justify="space-between"
              className="shadow-md px-5 py-4 sticky top-[91px] myFixedBg z-10"
            >
              <TextInput
                disabled={searchFriendProps.loading}
                rightSectionPointerEvents="all"
                placeholder="Do'stingizni ismini kiriting"
                className="w-full"
                value={searchFriendProps.value}
                error={searchFriendProps.error}
                onChange={onChangeSearchFriend}
                rightSection={
                  <X
                    aria-label="Clear input"
                    onClick={clearSearchFriendValue}
                    style={{
                      display: searchFriendProps.value ? undefined : "none",
                    }}
                  />
                }
                // rightSection={
                //   <Button
                //     loading={searchFriendProps.loading}
                //     className="w-full"
                //     onClick={handlerClickSearchFriend}
                //   >
                //     Qidirish
                //   </Button>
                // }
                // rightSectionProps={{ className: "w-[100px]" }}
              />
              <Button
                variant="filled"
                onClick={handlerClickSearchFriend}
                loading={searchFriendProps.loading}
              >
                <SearchIcon size={20} />
              </Button>
            </Flex>
            <Box className="mt-5 mx-5">
              {searchFriendProps.loading ? (
                <MySkelaton />
              ) : searchFriendProps.errorRes ? (
                <ResultPaper
                  title="Internal server error"
                  icon={<ServerOff size={40} />}
                />
              ) : (
                <Fragment>
                  <InfiniteScroll
                    className="overflow-hidden"
                    // endMessage={
                    //   <p className="text-center mt-0">
                    //     <b>Yay! You have seen it all</b>
                    //   </p>
                    // }
                    dataLength={searchResult.length}
                    next={() => fetchMoreData(pagingFriends.page)}
                    hasMore={metaFriends?.hasNextPage}
                    loader={
                      <Fragment>
                        {/* <LoadingOverlay
            visible={true}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ type: "dots" }}
          /> */}
                      </Fragment>
                    }
                    // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
                    // inverse={true} //
                  >
                    <motion.div
                      className="overflow-hidden pb-5"
                      initial="hidden"
                      animate="show"
                      variants={container}
                    >
                      {searchFriendProps.loading ? (
                        <LoadingOverlay
                          visible={true}
                          zIndex={1000}
                          overlayProps={{ radius: "sm", blur: 2 }}
                          loaderProps={{ type: "dots" }}
                        />
                      ) : searchResult?.length ? (
                        searchResult.map((user) => {
                          return (
                            <PostItemGlobal
                              key={user.userId}
                              addingUser={addingUser}
                              handlerAddAndRemoveUser={handlerAddAndRemoveUser}
                              // key={user.id}
                              user={user}
                              // removingLoading={removingLoading}
                              // openModalRemove={openModalRemove}
                            />
                          );
                        })
                      ) : (
                        <ResultPaper
                          title={searchFriendProps.resultText}
                          // subtitle={
                          //   <p className="mt-2 text-gray-400">
                          //     Iltimos, Do'stlarini qidirish bo'limiga o'tib <br />
                          //     <Anchor underline="never">Qarzim uz</Anchor> botida mavjud
                          //     do'stlaringizni qidiring va do'stlar qatoriga qo'shing.
                          //   </p>
                          // }
                        />
                      )}
                    </motion.div>
                  </InfiniteScroll>
                  {/* {searchResult?.length ? (
                    searchResult.map((user, index) => (
                      <Box key={user.userId}>
                        <div
                          role="button"
                          className="flex justify-between items-center w-full leading-tight transition-all rounded-lg outline-none text-start bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50 bg-opacity-80 text-blue-gray-900"
                        >
                          <div className="flex items-center">
                            <div className="grid mr-4 place-items-center">
                              {user.pathFile ? (
                                <img
                                  onClick={() => openUserProfile(user)}
                                  alt="alexander"
                                  src={`${"https://qarzim.uz/api"}/storage/files/${user.pathFile.slice(
                                    22,
                                    user.pathFile.length
                                  )}`}
                                  className="relative inline-block h-12 w-12 !rounded-full  object-cover object-center shadow-md"
                                />
                              ) : (
                                <Avatar className="w-12 h-12" />
                              )}
                            </div>
                            <div>
                              <h6 className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                                {user.fullName}
                              </h6>
                              <Text
                                span
                                className="mt-0"
                                inherit
                                c="var(--mantine-color-anchor)"
                              >
                                @{user.username}
                              </Text>
                            </div>
                          </div>
                          <div>
                            <Button
                              loading={addingUser === user.userId}
                              onClick={() => handlerAddAndRemoveUser(user)}
                              color={user.friend ? "red" : undefined}
                              leftSection={
                                !user.friend ? (
                                  <UserPlus size={14} />
                                ) : (
                                  <Trash2 size={14} />
                                )
                              }
                              variant="light"
                            >
                              {!user.friend ? "Qo'shish" : "O'chirish"}
                            </Button>
                          </div>
                        </div>
                        <Divider my="sm" />
                      </Box>
                    ))
                  ) : (
                    <ResultPaper
                      title={searchFriendProps.resultText}
                    />
                  )} */}
                  {/* <Box className="absolute bottom-[60px] w-[100%] left-0">
                    <Pagination
                      value={metaFriends.page}
                      onChange={handlerPaginSearch}
                      total={metaFriends?.totalPage}
                    />
                  </Box> */}
                </Fragment>
              )}
            </Box>
          </Tabs.Panel>
        </Tabs>
      </Box>
      <Modal.Root opened={opened} onClose={close} size={"sm"} centered>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{clickedUser?.fullName}</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Box className="flex justify-center">
              {/* <Image
                radius="md"
                h={500}
                w="auto"
                fit="contain"
                src={`${"https://qarzim.uz/api"}/storage/files/${clickedUser?.pathFile.slice(
                  22,
                  clickedUser.pathFile.length
                )}`}
              /> */}
              <Image
                // radius="md"
                // h={"80vh"}
                // w="auto"
                fit="contain"
                src={`${"https://qarzim.uz/api"}/storage/files/${
                  clickedUser?.pathFile
                }`}
                alt="it's me"
              />
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

export default Friends;
