import { Flex, Image, Modal } from "@mantine/core";
import React, { useContext } from "react";
import { Context } from "../../context";
import { OPEN_IMG_MODAL } from "../../context/actionTypes";
import { X } from "lucide-react";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";

const ImageViewerModal = () => {
  const { dispatch, state } = useContext(Context);

  const onClose = () => {
    dispatch({
      type: OPEN_IMG_MODAL,
      // payload: false,
    });
  };

  const images: any = [
    {
      src: state.imgSrc
        ? `${"https://qarzim.uz/api"}/storage/files/${state.imgSrc}`
        : "",
      // description: state.imgSrc,
    },
    // { src: "https://picsum.photos/200/300", description: "image-2" },
    // { src: "https://picsum.photos/200/300" },
    // { src: "https://picsum.photos/200/300" },
  ];
  // console.log("🚀 ~ ImageViewerModal ~ images:", images);

  return (
    <div className="absolute z-[500]">
      <ReactImageCarouselViewer
        disableScroll
        open={state.imgModal}
        onClose={onClose}
        images={images}
        startIndex={0}
      />
    </div>
  );
};

export default ImageViewerModal;
