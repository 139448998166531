import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loader from "./components/Loader";
import { useTelegram } from "./hooks/useTelegram";
import Container from "./layouts/Container";
import CustomComponent from "./layouts/CustomComponent";
import { routes } from "./route/Routes";
import { Badge } from "@mantine/core";
import ImageViewerModal from "./components/ImageViewer";
import NotFound from "./pages/NotFound";

const App = () => {
  const [loaderScreen, setLoaderScreen] = useState(true);
  const { onToggleButton, tg } = useTelegram();

  useEffect(() => {
    setLoaderScreen(true);
    tg.ready();

    setTimeout(() => {
      setLoaderScreen(false);
    }, 2000);
    // tg.MainButton.setParams({
    //   text: "Confirm button test",
    // });

    // tg.MainButton.show();
  }, []);

  return (
    <Container>
      <ImageViewerModal />

      {/* <Badge color="teal" className="fixed bottom-[20px] right-[20px] z-50">
        Test version
      </Badge> */}
      {/* {loaderScreen ? (
        <Loader />
      ) : ( */}
      <Routes>
        {routes?.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<CustomComponent Component={route.component} />}
          />
        ))}
        <Route path={"*"} element={<NotFound />} />
      </Routes>
      {/* )} */}
    </Container>
  );
};

export default App;
