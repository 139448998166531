import { Avatar, Badge, Box, Indicator, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Calculator, CircleFadingPlus, MessageSquare } from "lucide-react";
import React, { Fragment, ReactNode } from "react";
import { ReactCalculator } from "simple-react-calculator";
import UserProfile from "../UserProfile";

type PropsType = {
  title: string;
  children?: ReactNode;
  showCalc?: boolean;
  showAdd?: boolean;
  newMessage?: boolean;
  pb?: string;
};

const NavTitle = (props: PropsType) => {
  const {
    title,
    children,
    showCalc = false,
    showAdd = false,
    newMessage = false,
    pb = 2,
  } = props;
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Fragment>
      <div className="sticky top-0 z-10 myFixedBg shadow-md mb-3">
        <div className={`px-5 ${pb !== "0" ? `pb-${pb}` : ""}`}>
          <Box className="py-3 flex justify-between items-center relative">
            {!showCalc && <span></span>}
            {showCalc && (
              <Calculator className="cursor-pointer" onClick={open} />
            )}
            <Text fw={500} size="xl">
              {title}
            </Text>
            <span></span>
            {showAdd && <CircleFadingPlus className="cursor-pointer" />}

            {/* {newMessage && ( */}
            {/* <UserProfile /> */}
            {/* )} */}
          </Box>
          {children}
        </div>
      </div>
      <Modal
        transitionProps={{ transition: "slide-right" }}
        // withCloseButton={false}
        centered
        opened={opened}
        onClose={close}
        styles={{ body: { padding: 0 } }}
        size={"sm"}
        title="Calculator"
      >
        <div className="w-[100%] h-[520px] overflow-hidden">
          <ReactCalculator />
        </div>
      </Modal>
    </Fragment>
  );
};

export default NavTitle;
