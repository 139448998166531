import { MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import ReactDOM from "react-dom/client";
// import "react-phone-number-input/style.css";
import "react-international-phone/style.css";
import "@mantine/dropzone/styles.css";
import "@mantine/charts/styles.css";
// import "react-photo-editor/dist/style.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Provider from "./context";
import "./global.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
interface WindowWithTelegram extends Window {
  Telegram?: {
    WebApp: {
      colorScheme: string;
      // Define other properties here if needed
    };
  };
}

declare const window: WindowWithTelegram;
const colorSchemeTelegram = window.Telegram?.WebApp.colorScheme;

const theme = createTheme({
  cursorType: "pointer",
});

root.render(
  // <React.StrictMode>
  <MantineProvider
    theme={theme}
    // defaultColorScheme={"dark"}
    defaultColorScheme={
      colorSchemeTelegram === "light"
        ? "light"
        : colorSchemeTelegram === "dark"
        ? "dark"
        : "auto"
    }
  >
    <ModalsProvider>
      <Provider>
        <BrowserRouter basename="/">
          <Notifications position="bottom-center" />
          <App />
        </BrowserRouter>
      </Provider>
    </ModalsProvider>
  </MantineProvider>
  // </React.StrictMode>
);
