import { useContext } from "react";
import { Context } from "../context";
import { CHANGE_TRIGGER } from "../context/actionTypes";

// Custom hook function
function useChangeTrigger() {
  const { dispatch } = useContext(Context);

  const changeTrigger = () => {
    dispatch({
      type: CHANGE_TRIGGER,
    });
  };

  return changeTrigger;
}

export { useChangeTrigger };
