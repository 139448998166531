import { Fragment } from "react";
import DefaultExport from "./components";
import { StickyNavTitle } from "./imports";

const Lend = () => {
  return (
    <Fragment>
      {/* <div className="shadow-md mb-3"> */}
      <StickyNavTitle title="Qarz berdim" showCalc />
      {/* </div> */}

      <DefaultExport.Forms />
    </Fragment>
  );
};

export default Lend;
