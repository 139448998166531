import { Fragment } from "react";
import DefaultExport from "./components";
import { StickyNavTitle } from "./imports";

const Borrow = () => {
  return (
    <Fragment>
      <StickyNavTitle title="Qarz oldim" showCalc />

      <DefaultExport.Forms />
    </Fragment>
  );
};

export default Borrow;
