import { useContext } from "react";
import { Context } from "../../context";

interface PropType {
  children: any;
}

const Container = (props: PropType) => {
  const { state } = useContext(Context);

  const { children } = props;
  return <div className={`w-[100%] text-center myContainer`}>{children}</div>;
};

export default Container;
