import { Box, Flex, Text } from "@mantine/core";
import { Frown } from "lucide-react";
import React from "react";

const NotFound = () => {
  return (
    <Box className="px-5 pt-5">
      <div className="centerStyle">
        <Flex direction={"column"} align={"center"}>
          <Frown size={30} />
          <Text size="lg">Sahifa topilmadi</Text>
          <Text size="md" c={"dimmed"}>
            Iltimos botga qaytadan{" "}
            <Text className="inline-block" c={"blue"}>
              /start
            </Text>{" "}
            buyrug'ini bering
          </Text>
        </Flex>
      </div>
    </Box>
  );
};

export default NotFound;
