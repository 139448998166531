import {
  Box,
  Flex,
  Input,
  Modal,
  Paper,
  ScrollArea,
  Text,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ChevronDown, Search } from "lucide-react";
import React, { Fragment, useEffect, useState } from "react";
import {
  FlagImage,
  PhoneInput,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { isPhoneValid } from "../CreateFriendButton";

interface PropsType {
  phoneValue: any;
  setPhoneValue: any;
  form: any;
  setErrorPhone: any;
  errorPhone: any;
}

const MantinePhone = (props: PropsType) => {
  const { setPhoneValue, phoneValue, form, errorPhone, setErrorPhone } = props;
  //   const [phone, setPhone] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [countries, setCountries] = useState(defaultCountries);
  const {
    inputValue,
    phone,
    country,
    setCountry,
    handlePhoneValueChange,
    inputRef,
  } = usePhoneInput({
    // forceDialCode: true,
    defaultCountry: "uz",
    // value: "+998",
    onChange: ({ phone, inputValue, country }: any) => {
      setPhoneValue(phone);
      // console.log("🚀 ~ MantinePhone ~ phone:", phone);
      // make something on change
    },
  });

  useEffect(() => {
    // console.log("🚀 ~ MantinePhone ~ inputValue:", defaultCountries);
    // console.log(
    //   "🚀 ~ MantinePhone ~ inputValue:",
    //   parseCountry(defaultCountries[0])
    // );
    // setCountry("ad");
    // console.log("defaultCountries: ", defaultCountries);
  }, [inputValue]);

  const handleSearchCountry = (e: any) => {
    const value = e.target.value;
    // setSearchQuery(value);

    const filteredCountries = defaultCountries.filter((c) =>
      parseCountry(c).name.toLowerCase().includes(value.toLowerCase())
    );
    setCountries(filteredCountries);

    // console.log(
    //   "🚀 ~ handleSearchCountry ~ filteredCountries:",
    //   filteredCountries
    // );
    // setSearchQuery(e.target.value)
  };

  const onClose = () => {
    close();
    setTimeout(() => {
      setSearchQuery("");
      setCountries(defaultCountries);
    }, 1000);
  };

  const handleSelectCountryPhone = (country: any) => {
    setCountry(country.iso2);
    onClose();
  };

  const handlePhoneChange = (e: any) => {
    const cleanedArr = e.target.value.replace(/\s/g, "");

    handlePhoneValueChange(e);
    setErrorPhone("");
    // setErrorPhone(isPhoneValid(cleanedArr) ? "" : "Phone number is not valid");
  };

  const handleKeyDown = (e: any) => {
    const keyCode = e?.keyCode;
    console.log("🚀 ~ handleKeyDown ~ e:", keyCode);
  };

  return (
    <Fragment>
      <TextInput
        type="tel"
        {...(form.getInputProps("phone"),
        {
          onChange: handlePhoneChange,
          error: errorPhone,
        })}
        leftSectionWidth={50}
        leftSection={
          <Flex align={"center"} className="cursor-pointer" onClick={open}>
            <FlagImage
              className="mx-1"
              iso2={country.iso2}
              // style={{ display: "flex" }}
            />
            <ChevronDown size={16} />
          </Flex>
        }
        value={inputValue}
        // onChange={handlePhoneValueChange}
        label={"Telefon raqami"}
        className="w-full"
      />
      <Modal.Root opened={opened} onClose={onClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Select country</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Box>
              <Input
                data-autofocus
                leftSection={<Search className="w-4 h-4" />}
                // variant="unstyled"
                size="md"
                placeholder="Search"
                onKeyDown={handleKeyDown}
                onChange={handleSearchCountry}
                className="mb-5"
              />
              <ScrollArea type="auto" offsetScrollbars h={500}>
                {countries?.length ? (
                  countries?.map((c) => {
                    const country = parseCountry(c);
                    return (
                      <Paper
                        onClick={() => handleSelectCountryPhone(country)}
                        key={country.iso2}
                        shadow="sm"
                        radius="md"
                        withBorder
                        className="cursor-pointer mb-2"
                        p="sm"
                      >
                        <Flex justify={"space-between"}>
                          <Box className="flex">
                            <FlagImage
                              className="mr-2"
                              onClick={open}
                              iso2={country.iso2}
                            />
                            <Text>{country.name}</Text>
                          </Box>
                          <Text>+{country.dialCode}</Text>
                        </Flex>
                      </Paper>
                    );
                  })
                ) : (
                  <h1>Country not found</h1>
                )}
              </ScrollArea>
            </Box>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {/* <PhoneInput
        // disableDialCodeAndPrefix={true}
        // showDisabledDialCodeAndPrefix={true}
        className="w-full"
        forceDialCode={true}
        defaultCountry="uz"
        value={phone}
        disableFocusAfterCountrySelect
        onChange={(phone) => setPhone(phone)}
      /> */}
    </Fragment>
  );
};

export default MantinePhone;
