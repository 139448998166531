import React from "react";
import { Box, Divider, Skeleton } from "@mantine/core";

const MySkelaton = ({ length = 5 }) => {
  // Generate an array of length `length` filled with arbitrary values
  const skeletonArray = Array.from({ length });

  return (
    <Box>
      {/* Map over the skeletonArray and render a Skeleton component for each element */}
      {skeletonArray.map((_, index) => (
        <React.Fragment key={index}>
          <Box className="flex items-center">
            <Skeleton
              className="w-[50px] h-[50px] pr-[50px]"
              width={50}
              height={50}
              circle
            />
            <Box className="w-full ml-3">
              <Skeleton height={8} width="40%" radius="xl" />
              <Skeleton height={8} mt={6} width="20%" radius="xl" />
            </Box>
            <Skeleton width={"100px"} height={"35px"} />
          </Box>
          {/* Render a Divider component after each set of skeletons */}
          {index < length - 1 && <Divider my="sm" />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MySkelaton;
