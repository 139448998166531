import { useCallback, useRef, useState } from "react";
import { FormValues } from "./types";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { useTelegram } from "./imports";

const useFormSubmission = () => {
  const [qachondan, setQachondan] = useState<Date | any>(null);
  const [qachongacha, setQachongacha] = useState<Date | any>(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [errorFromWhom, setErrorFromWhom] = useState("");
  const [valueCom, setValueCom] = useState<string | null>(null);
  const [visibleTable, setVisibleTable] = useState(false);
  const { tg } = useTelegram();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [payInInstallments, setPayInInstallments] = useState<number>(1);
  const [opened, { toggle }] = useDisclosure(false);
  const formValues = useForm({
    initialValues: {
      toWhomId: "",
      howMuch: "",
      reason: "",
      lendingDate: qachondan,
      returnDate: qachongacha,
      onReminder: false,
      payInInstallments: false,
      distributionDto: {},
    },
    validate: {
      // fromWhom: (value) =>
      //   value.length < 1
      //     ? "Iltimos, qarz olayotgan do'stingizni tanlang"
      //     : null,
      howMuch: (value) =>
        value.length < 1
          ? "Iltimos, qancha qarz olayotganingizni kiriting"
          : null,
      lendingDate: (value) =>
        !value ? "Iltimos, qarz olgan sanangizni kiriting" : null,
      returnDate: (value) =>
        !value
          ? "Iltimos, qarzingizni qaytarib beradigan sanangizni kiriting"
          : null,
    },
  });

  const onSendData = useCallback(() => {
    const form = formValues.values;
    const data = {
      toWhomId: form.toWhomId,
      howMuch: form.howMuch.replaceAll(".", ""),
      reason: form.reason,
      lendingDate: qachondan,
      returnDate: qachongacha,
      onReminder: form.onReminder,
      payInInstallments: form.payInInstallments,
      distributionDto: form.distributionDto,
    };
    console.log("🚀 ~ onSendData ~ data:", data);
    // Assuming tg is defined somewhere
    if (valueCom) {
      tg.sendData(JSON.stringify(data));
    } else {
      setErrorFromWhom("Please select your friend");
    }
  }, [formValues]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      // setFormValues(values);
      onSendData();
      // console.log("values: ", values);
    },
    [onSendData]
  );

  const handleGenerateTable = () => {
    setLoadingTable(true);
    setVisibleTable(false);

    setTimeout(() => {
      setLoadingTable(false);
      setVisibleTable(true);
    }, 2000);
  };

  const handlerPayInInst = (e: any) => {
    console.log("11");

    toggle();
    setPayInInstallments(e ? 1 : 0);
  };

  const handleButtonClick = () => {
    buttonRef.current?.click();
  };

  return {
    valueCom,
    setValueCom,
    errorFromWhom,
    setErrorFromWhom,
    qachondan,
    qachongacha,
    setQachondan,
    setQachongacha,
    onSubmit,
    formValues,
    onSendData,
    loadingTable,
    visibleTable,
    handleGenerateTable,
    openedToggle: opened,
    payInInstallments,
    setPayInInstallments,
    handlerPayInInst,
    handleButtonClick,
    buttonRef,
  }; // returning both the handleSubmit function and formValues
};

export default useFormSubmission;
