import {
  Avatar,
  Blockquote,
  Box,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  rem,
  Image as ImageMantine,
  ActionIcon,
  Notification,
} from "@mantine/core";
import {
  AtSign,
  Check,
  Eye,
  Image,
  ImagePlus,
  Info,
  MessageCircleX,
  Phone,
  PhoneOutgoing,
  Trash,
  UploadIcon,
  UserRound,
  X,
} from "lucide-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import instance from "../../configs/axios";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { Context } from "../../context";
import { usePhoneInput } from "react-international-phone";
import { isNotEmpty, useForm } from "@mantine/form";
import { AxiosResponse } from "axios";
import { OPEN_IMG_MODAL } from "../../context/actionTypes";
import { useTelegram } from "../Borrow/imports";
import { notifications } from "@mantine/notifications";
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
} from "@mantine/dropzone";
import { ReactPhotoEditor } from "react-photo-editor";
import { modals } from "@mantine/modals";

interface UserType {
  createdAt: string;
  phoneNumber: string;
  fullName: string;
  id: number;
  visibilityPhoneNumber: "EVERYONE" | "MY_FRIENDS" | "NO_ONE";
  pathFile: string;
  username: string;
}

interface FormValuesType {
  fullName: string;
  username: string;
  phoneNumber: string;
  visibilityPhoneNumber: "EVERYONE" | "MY_FRIENDS" | "NO_ONE";
}

const Profile = () => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [editedFiles, setEditedFiles] = useState<FileWithPath[]>([]);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { currentUserId } = useParams();
  const [usernameError, setUsernameError] = useState<string>("");
  const { tg, onClose, onToggleButton } = useTelegram();
  const [opened, { open, close }] = useDisclosure(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [user, setUser] = useState<UserType>();
  const { dispatch } = useContext(Context);
  const [valueUsername, setValueUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(false);
  const [editorModal, setEditorModal] = useState(false);
  const {
    inputValue,
    phone,
    country,
    setCountry,
    handlePhoneValueChange,
    inputRef,
  } = usePhoneInput({
    // forceDialCode: true,
    value: user?.phoneNumber,
    onChange: ({ phone, inputValue, country }: any) => {
      // make something on change
    },
  });

  const form = useForm({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      username: "",
      visibilityPhoneNumber: "",
    },

    validate: {
      fullName: isNotEmpty("Iltimos, ismingizni kiriting!"),
      username: isNotEmpty("Iltimos, username kiriting!"),
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await instance(`/user/about-me`);
        const data: UserType = res?.data?.data;

        const value: FormValuesType = {
          fullName: data?.fullName ? data?.fullName : "",
          phoneNumber: data?.phoneNumber ? inputValue : "",
          username: data?.username ? `${data?.username}` : "",
          visibilityPhoneNumber: data?.visibilityPhoneNumber,
        };
        setValueUsername(data?.username ? `${data?.username}` : "");
        form.setValues(value);
        setUser(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSendData = useCallback(() => {
    const data = {
      test: "Lorem ipsum",
    };
    tg.sendData(JSON.stringify(data));
  }, [form]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: "Saqlash",
      // color: "#31708E",
    });
    tg.MainButton.show();
  }, []);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", handleButtonClick);
    return () => {
      tg.offEvent("mainButtonClicked", handleButtonClick);
    };
  }, []);

  const handleButtonClick = () => {
    // onClose();
    buttonRef.current?.click();
  };

  const onFinish = async (values: any) => {
    // onSendData();
    if (!usernameError) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("fullName", values?.fullName);
        formData.append("username", values?.username);
        formData.append("visibilityPhoneNumber", values?.visibilityPhoneNumber);

        const res = await instance.put(`/user/update`, formData);
        notifications.show({
          color: "green",
          message: "Ma'lumotlar muvaffaqiyatli yangilandi",
        });
      } catch (error: any) {
        if (error?.response?.status === 500) {
          notifications.show({
            color: "red",
            message: "Internal server error!",
          });
        } else if (error?.response?.status === 400) {
          notifications.show({
            color: "red",
            message: "Bad Request",
          });
        } else {
          notifications.show({
            color: "red",
            message: `Error with status code: ${error?.response?.status}`,
          });
        }
      } finally {
        setLoading(false);
      }
    } else {
    }
  };

  const onChangeUsername = async (e: any) => {
    const value: string = e?.target?.value;
    form.setFieldValue("username", value.replace(/\s+/g, ""));
    setUsernameError("");
    setValueUsername(value.replace(/\s+/g, ""));

    if (value) {
      if (!value.startsWith("@")) {
        const handler = setTimeout(async () => {
          try {
            const res = await instance.get(
              `/user/exist-username?username=${value}`
            );
            setUsernameError("");
            // form.errors.username = "";
          } catch (error: any) {
            if (error?.response?.status === 409) {
              setUsernameError("Bu username mavjud");
            } else {
              notifications.show({
                color: "red",
                message: "Internal server error!",
              });
            }
          }
        }, 100);

        return () => {
          clearTimeout(handler);
        };
      } else {
        setUsernameError(`"@" belgisini ishlatmang`);
      }
    }
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <ImageMantine
        key={index}
        src={imageUrl}
        // w={300}
        h={300}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
      />
    );
  });

  const onDrop = (file: FileWithPath[]) => {
    setFiles(file);
  };

  const onReject = (rejectedFile: FileRejection[]) => {
    rejectedFile?.forEach((file) => {
      file.errors?.forEach((error) => {
        if (error.code === "file-invalid-type") {
          notifications.show({
            color: "red",
            message: "Rasm turi image/png, image/jpeg bo'lishi kerak",
          });
        } else if (error.code === "file-too-large") {
          notifications.show({
            color: "red",
            message: "Rasm 5 MB dan katta",
          });
        }
      });
      console.log("🚀 ~ onReject ~ file:", file);
    });
  };

  const closeUploadModal = () => {
    onToggleButton();
    setUploadModal(false);
    setFiles([]);
  };
  const openUploadModal = () => {
    onToggleButton();
    setUploadModal(true);
  };

  const saveImg = async () => {
    if (files?.length) {
      try {
        setLoadingImg(true);
        const formData = new FormData();
        formData.append("photo", files?.[0]);
        const res = await instance.put(`/user/update/photo`, formData);
        console.log("🚀 ~ saveImg ~ res:", res);
        const updatedUser: any = {
          ...user,
          pathFile: res?.data?.data?.pathFile,
        };
        setUser(updatedUser);
        notifications.show({
          color: "green",
          message: "Rasm muvaffaqiyatli yuklandi",
        });
        closeUploadModal();
      } catch (error: any) {
        notifications.show({
          color: "red",
          message: `Error with status code: ${error?.response?.status}`,
        });
        console.log("🚀 ~ saveImg ~ error:", error);
      } finally {
        setLoadingImg(false);
      }
    } else {
      notifications.show({
        color: "yellow",
        message: "Rasm yuklanmagan!",
      });
    }
  };

  const openDeleteModal = () => {
    onToggleButton();
    modals.openConfirmModal({
      title: "Rasmni o'chirmoqchimisiz?",
      closeOnClickOutside: false,
      withCloseButton: false,
      labels: { confirm: "Xa", cancel: "Yo'q" },
      onCancel: () => {
        onToggleButton();
        console.log("Cancel");
      },
      onConfirm: async () => {
        onToggleButton();
        const id = notifications.show({
          loading: true,
          title: "Oʻchirilmoqda...",
          message: null,
          autoClose: false,
          withCloseButton: false,
        });
        try {
          const res = await instance.delete(`/user/delete/photo`);
          const updatedUser: any = {
            ...user,
            pathFile: "",
          };
          setUser(updatedUser);
          notifications.update({
            id,
            color: "teal",
            title: "Rasm oʻchirildi",
            message: null,
            icon: <Check style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
            autoClose: 2000,
          });
          console.log("🚀 ~ onConfirm: ~ res:", res);
        } catch (error) {
          notifications.update({
            id,
            color: "red",
            title: "Oʻchirib boʻlmadi",
            message: null,
            loading: false,
            autoClose: 2000,
          });
          console.log("🚀 ~ onConfirm:async ~ error:", error);
        }
      },
    });
  };

  return (
    <Box className="px-5 pt-5">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Box>
        <Flex justify={"center"} direction={"column"} align={"center"}>
          <div
            className="relative cursor-pointer"
            onClick={() =>
              user?.pathFile &&
              dispatch({
                type: OPEN_IMG_MODAL,
                payload: user.pathFile,
              })
            }
          >
            {user?.pathFile ? (
              <div className={`absolute showImageHover`}>
                {/* <span className="text-orange-50">Show</span> */}
                <Eye size={16} color="white" />
              </div>
            ) : (
              ""
            )}
            <Avatar
              size={"xl"}
              src={
                user?.pathFile
                  ? `${"https://qarzim.uz/api"}/storage/files/${user?.pathFile}`
                  : ""
              }
              alt="it's me"
            />
          </div>
          <Flex className="mt-3 w-full" justify={"center"} mb={20}>
            <Button
              fullWidth
              leftSection={<ImagePlus size={16} />}
              variant="outline"
              onClick={openUploadModal}
            >
              Rasmn yuklash
            </Button>
            <Button
              disabled={user?.pathFile ? false : true}
              fullWidth
              leftSection={<Trash size={16} />}
              variant="outline"
              onClick={openDeleteModal}
              color="red"
              ml={10}
            >
              Rasmnni o'chirish
            </Button>
            {/* <ActionIcon
              size={"lg"}
              ml={10}
              variant="filled"
              color="red"
              aria-label="Settings"
            >
              <Trash size={16} />
            </ActionIcon> */}
          </Flex>
        </Flex>
        <Modal
          opened={uploadModal}
          onClose={closeUploadModal}
          title="Rasm yuklash"
          centered
          size={"xl"}
        >
          <Flex
            direction={"column"}
            justify={"space-between"}
            // h={450}
            className="relative"
          >
            {/* <Text size="md" mb={10}>
              Rasm yuklash maydoni
            </Text> */}
            {files?.length ? (
              <Flex justify={"center"}>
                <Box className="relative">
                  <ActionIcon
                    className="absolute right-2 top-2"
                    variant="filled"
                    color="red"
                    onClick={() => setFiles([])}
                    aria-label="Settings"
                  >
                    <X />
                  </ActionIcon>
                  {previews}
                </Box>
              </Flex>
            ) : (
              <Dropzone
                onDrop={(files) => onDrop(files)}
                multiple={false}
                onReject={(files) => onReject(files)}
                maxSize={5 * 1024 ** 2}
                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                // {...props}
              >
                <Group
                  justify="center"
                  gap="sm"
                  mih={300}
                  style={{ pointerEvents: "none", alignContent: "center" }}
                >
                  <Dropzone.Accept>
                    <UploadIcon
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <X
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <Image
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: "var(--mantine-color-dimmed)",
                      }}
                    />
                  </Dropzone.Idle>

                  <div className="ml-3">
                    <Text size="xl" inline>
                      Rasmni yuklash uchun bosing
                    </Text>
                    <Text size="sm" c="dimmed" inline mt={7}>
                      Rasm 5mb dan oshmasligi kerak
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            )}
            <Button loading={loadingImg} mt={10} fullWidth onClick={saveImg}>
              Saqlash
            </Button>
          </Flex>
        </Modal>
        <form
          className="text-left"
          onSubmit={form.onSubmit((values) => onFinish(values))}
        >
          <Flex
            gap="md"
            justify="flex-start"
            align="flex-start"
            direction="column"
            wrap="wrap"
          >
            <TextInput
              rightSection={<UserRound size={16} />}
              className="w-full"
              label="To'liq ismingiz"
              placeholder="Ism va Familyangizni kiriting"
              {...form.getInputProps("fullName")}
            />

            <TextInput
              // disabled
              readOnly
              type="tel"
              rightSection={<Phone size={16} />}
              className="w-full"
              value={inputValue}
              label="Telefon raqamingiz"
              description={"Hozirda telefon raqamini o'zgartirishni iloji yo'q"}
              placeholder=""
              // {...form.getInputProps("phoneNumber")}
            />
            <Select
              // readOnly
              checkIconPosition="right"
              className="w-full"
              label="Kim mening telefon raqamimni ko'radi"
              placeholder="Pick value"
              {...form.getInputProps("visibilityPhoneNumber")}
              data={[
                { value: "EVERYONE", label: "Hamma" },
                { value: "NO_ONE", label: "Hechkim" },
                {
                  value: "MY_FRIENDS",
                  label: "Faqat mening do'stlarim",
                },
              ]}
            />

            <TextInput
              rightSection={<AtSign size={16} />}
              className="w-full"
              label="Username"
              placeholder=""
              {...(form.getInputProps("username"),
              {
                onChange: onChangeUsername,
                value: valueUsername,
                error: usernameError,
              })}
            />
          </Flex>
          <Flex justify={"end"}>
            <Button ref={buttonRef} type="submit" className="mt-3 hidden">
              Saqlash
            </Button>
          </Flex>
          {/* <Group justify="flex-end" mt="md">
      <Button type="submit" fullWidth >
        Yangilash
      </Button>
    </Group> */}
          {/* <Group justify="flex-end" mt="md">
      <Button type="submit" fullWidth>
        Yangilash
      </Button>
    </Group> */}
        </form>
      </Box>
      {/* <Box className="px-2">
        <Blockquote
          color="blue"
          cite={`Shaxsiy ma'lumotlaringizni botdagi "Sozlamalar" bo'limidan o'zgartirishingiz mumkin`}
          icon={<Info />}
          mt="xl"
          p={"lg"}
        >
          Diqqat!
        </Blockquote>
      </Box> */}
    </Box>
  );
};

export default Profile;
