import React, { createContext, useReducer, ReactNode, useState } from "react";
import { CHANGE_THEME, CHANGE_TRIGGER, OPEN_IMG_MODAL } from "./actionTypes";
import { ThemeTypes } from "../models/ThemeTypes/type";
import { QARZIM_THEME } from "../constants";

interface InitialState {
  theme: ThemeTypes;
  trigger: boolean;
  imgModal: boolean;
  imgSrc: string | null;
}

interface Action {
  type: string;
  payload?: any;
}

function themeStorageOrScheme(): ThemeTypes {
  const local_storage = localStorage.getItem(QARZIM_THEME);
  const color_scheme_isLight = window.matchMedia(
    "(prefers-color-scheme: light)"
  ).matches;

  if (local_storage) {
    if (local_storage === ThemeTypes.dark) {
      return ThemeTypes.dark;
    } else if (local_storage === ThemeTypes.light) {
      return ThemeTypes.light;
    } else {
      return color_scheme_isLight ? ThemeTypes.light : ThemeTypes.dark;
    }
  } else {
    return color_scheme_isLight ? ThemeTypes.light : ThemeTypes.dark;
  }
}

const initialState: InitialState = {
  theme: themeStorageOrScheme(),
  trigger: false,
  imgModal: false,
  imgSrc: null,
};

export const Context = createContext<{
  state: InitialState;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => null });

const reducer = (state: InitialState, action: Action): InitialState => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_THEME:
      localStorage.setItem(QARZIM_THEME, payload);
      return {
        ...state,
        theme: payload,
      };
    case CHANGE_TRIGGER:
      return {
        ...state,
        trigger: !state.trigger,
      };
    case OPEN_IMG_MODAL:
      return {
        ...state,
        imgModal: payload,
        ...(payload && { imgSrc: payload }),
      };
    default:
      return state;
  }
};

interface ProviderProps {
  children: ReactNode;
}

const Provider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Provider;
