import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { MyFriend } from "../../pages/Friends";
import FriendViewOrEdit from "../FriendViewOrEdit";

const PostItem = (props: {
  user: MyFriend;
  removingLoading: number | null;
  openModalRemove: (id: number, fullName: string) => void;
}) => {
  const { user, removingLoading, openModalRemove } = props;
  // console.log("🚀 ~ PostItem ~ props:", user);
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    }
  }, [controls, inView]);

  const item = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
  };
  return (
    <motion.div
      variants={item}
      initial="hidden"
      animate={controls}
      ref={ref}
      // className="overflow-hidden"
    >
      <FriendViewOrEdit user={user} openModalRemove={openModalRemove} />
      {/* <Box>
          <Paper shadow="xs" radius="md" withBorder p="sm" className="mb-2">
            <div
              role="button"
              className="flex justify-between items-center w-full leading-tight transition-all rounded-lg outline-none text-start bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50 bg-opacity-80 text-blue-gray-900"
            >
              <div className="flex items-center">
                <div className="grid mr-4 place-items-center">
                  {user.pathFile ? (
                    <img
                      alt="alexander"
                      src={`${"https://qarzim.uz/api"}/storage/files/${user.pathFile.slice(
                        22,
                        user.pathFile.length
                      )}`}
                      className="relative inline-block h-12 w-12 !rounded-full  object-cover object-center shadow-md"
                    />
                  ) : (
                    <Avatar className="w-12 h-12" />
                  )}
                </div>
                <div>
                  <Text className="truncate w-[100px]">{user.fullName} </Text>
                  <Text
                    className="mt-0 truncate w-[100px]"
                    inherit
                    c="var(--mantine-color-anchor)"
                  >
                    @{user.username}
                  </Text>
                </div>
              </div>
              <div>
                <EditFriendButton user={user} />
                <Button
                  loading={removingLoading === user.id}
                  onClick={() => openModalRemove(user.id, user.fullName)}
                  variant="light"
                  color="red"
                >
                  <Trash2 size={16} />
                </Button>
              </div>
            </div>
          </Paper>
        </Box> */}
    </motion.div>
  );
};
export default PostItem;
