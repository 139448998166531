import { LoadingOverlay, Text, rem } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { motion } from "framer-motion";
import { Check } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import instance from "../../configs/axios";
import { MyFriend, TypeMyFriendsProps } from "../../pages/Friends";
import { useChangeTrigger } from "../../utils";
import PostItem from "../PostItem";
import ResultPaper from "../ResultPaper";

type PropsType = {
  currentUserId: any;
  triggerTabs: boolean;
  triggerSearch: boolean;
  myFriendProps: TypeMyFriendsProps;
  setMyFriendProps: any;
};

type MetaTypes = {
  hasNextPage: boolean;
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const ListMyFriends = (props: PropsType) => {
  const {
    currentUserId,
    triggerTabs,
    triggerSearch,
    myFriendProps,
    setMyFriendProps,
  } = props;
  const changeTrigger = useChangeTrigger();
  const [meta, setMeta] = useState<MetaTypes>({ hasNextPage: true });
  const [items, setItems] = useState<MyFriend[]>([]);
  const [loading, setLoading] = useState(true);
  const [removingLoading, setRemovingLoading] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);

  const fetch = async (pageNumber: number = 1, trigger?: boolean) => {
    try {
      const res = await instance(`/friend`, {
        params: {
          ...(myFriendProps.value && { search: myFriendProps.value }),
          page: pageNumber,
          size: 10,
        },
      });
      const data: MyFriend[] = res?.data?.data?.items;
      const meta: MetaTypes = res?.data?.data?.meta;
      if (!data?.length) {
        setMyFriendProps((prevState: any) => ({
          ...prevState,
          resultText: "Bunday do'st topilmadi",
        }));
      }

      setMeta(meta);
      if (trigger) {
        setItems(data);
      } else {
        setItems((prevItems) => [...prevItems, ...data]);
      }
    } catch (error) {
      console.log("🚀 ~ loadMoreItems ~ error:", error);
    } finally {
      setPage((p) => p + 1);
      setLoading(false);
    }
  };

  const fetchMoreData = (page: number) => {
    return fetch(page);
  };

  useEffect(() => {
    setPage(1);
    setLoading(true);
    setItems([]);
    fetch(1, true);
  }, [triggerTabs, triggerSearch]);

  // useEffect(() => {
  //   setItems([]);
  //   fetch(page, !triggerTabs);
  // }, []);

  // if (!items.length) {
  //   return (
  //     <LoadingOverlay
  //       visible={true}
  //       zIndex={1000}
  //       overlayProps={{ radius: "sm", blur: 2 }}
  //       loaderProps={{ type: "dots" }}
  //     />
  //   );
  // }

  // Remove from friends
  const handlerRemoveUser = async (id: number, fullname: string) => {
    try {
      setRemovingLoading(id);
      const res = await instance(`/friend/remove/${id}`, {
        method: "DELETE",
      });

      notifications.show({
        icon: <Check style={{ width: rem(18), height: rem(18) }} />,
        color: "green",
        title: fullname,
        message: `Do'stlaringiz safidan o'chirildi`,
      });
      changeTrigger();
    } catch (error) {
    } finally {
      setRemovingLoading(null);
    }
  };
  const openModalRemove = (id: number, fullName: string) =>
    modals.openConfirmModal({
      // onBlurCapture: true,
      // centered: true,
      title: "Do'stni o'chirish",
      children: (
        <Text className="text-center" size="md">
          {fullName} - ni xaqiqatdan ham do'stlar safidan oʻchirmoqchimisiz?
        </Text>
      ),
      labels: { confirm: "Xa", cancel: "Yo'q" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handlerRemoveUser(id, fullName),
    });

  return (
    <Fragment>
      <InfiniteScroll
        className="overflow-hidden"
        // endMessage={
        //   <p className="text-center mt-0">
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        dataLength={items?.length}
        next={() => fetchMoreData(page)}
        hasMore={meta?.hasNextPage}
        loader={
          <Fragment>
            {/* <LoadingOverlay
            visible={true}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
            loaderProps={{ type: "dots" }}
          /> */}
          </Fragment>
        }
        // style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
        // inverse={true} //
      >
        <motion.div
          className="overflow-hidden pb-5"
          initial="hidden"
          animate="show"
          variants={container}
        >
          {loading ? (
            <LoadingOverlay
              visible={true}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
              loaderProps={{ type: "dots" }}
            />
          ) : items?.length ? (
            items.map((user) => {
              return (
                <PostItem
                  key={user.id}
                  user={user}
                  removingLoading={removingLoading}
                  openModalRemove={openModalRemove}
                />
              );
            })
          ) : (
            <ResultPaper
              title={myFriendProps.resultText}
              // subtitle={
              //   <p className="mt-2 text-gray-400">
              //     Iltimos, Do'stlarini qidirish bo'limiga o'tib <br />
              //     <Anchor underline="never">Qarzim uz</Anchor> botida mavjud
              //     do'stlaringizni qidiring va do'stlar qatoriga qo'shing.
              //   </p>
              // }
            />
          )}
        </motion.div>
      </InfiniteScroll>
    </Fragment>
  );
};

export default ListMyFriends;
