import {
  Avatar,
  Badge,
  Blockquote,
  Box,
  Button,
  Card,
  CloseButton,
  Collapse,
  Divider,
  Flex,
  Group,
  Image,
  Modal,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { MyFriend } from "../../pages/Friends";
import {
  Check,
  Eye,
  Info,
  Pencil,
  PhoneOutgoingIcon,
  Trash2,
  X,
} from "lucide-react";
import { Context } from "../../context";
import { useDisclosure } from "@mantine/hooks";
import IsVerifed from "../IsVerifed";
import { isNotEmpty, useForm } from "@mantine/form";
import instance from "../../configs/axios";
import { useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { useChangeTrigger } from "../../utils";
import { OPEN_IMG_MODAL } from "../../context/actionTypes";
import { PhoneInput } from "react-international-phone";
import PhoneViewer from "../PhoneViewer";

type PropsType = {
  user: MyFriend;
  openModalRemove: (id: number, fullName: string) => void;
};

type ValuesType = {
  fullName: string;
  userName: string;
};

const FriendViewOrEdit = (props: PropsType) => {
  const { user, openModalRemove } = props;
  const { dispatch } = useContext(Context);
  const changeTrigger = useChangeTrigger();
  const { currentUserId } = useParams();
  const [modalImage, setModalImage] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [isOpenQuote, setIsOpenQuote] = useState(true);
  const [modalType, setModalType] = useState<"view" | "edit">("view");
  const form = useForm({
    initialValues: {
      fullName: user.fullName,
      userName: user.username ? `@${user.username}` : "",
    },

    validate: {
      fullName: isNotEmpty("Name is required"),
    },
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsOpenQuote(true);
  //   }, 2000);
  // }, []);

  const onSubmit = (values: ValuesType) => {
    updateFriendApi();
  };

  const updateFriendApi = async () => {
    try {
      const res = await instance(`/friend/rename/${user.id}`, {
        method: "PUT",
        params: {
          rename: form.values.fullName,
        },
      });
      notifications.show({
        icon: <Check style={{ width: rem(18), height: rem(18) }} />,
        color: "green",
        title: "Muvaffaqiyatli yangilandi",
        message: undefined,
      });
      changeTrigger();
      close();
    } catch (error) {}
  };

  const handlerView = (type: "edit" | "view") => {
    setModalType(type);
    open();
  };
  const onClose = () => {
    form.reset();
    close();
  };

  const closeModalImage = () => {
    setModalImage(false);
  };

  return (
    <Card shadow="sm" padding="sm" radius="md" withBorder className="mb-2">
      <Card.Section pt={"sm"}>
        <Flex justify={"center"}>
          <Avatar
            className="shadow-md"
            size={"xl"}
            onClick={() =>
              user.pathFile &&
              dispatch({
                type: OPEN_IMG_MODAL,
                payload: user.pathFile,
              })
            }
            src={
              user.pathFile
                ? `${"https://qarzim.uz/api"}/storage/files/${user.pathFile}`
                : ""
            }
          />
        </Flex>
        {/* <Image
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
          height={160}
          alt="Norway"
        /> */}
      </Card.Section>
      <Box mt="sm" mb="xs">
        <Group justify="space-between">
          <Text size="sm" c="dimmed">
            Ismi:
          </Text>
          <Text fw={500}>{user?.fullName}</Text>
        </Group>
        {user?.phoneNumber && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Telefon raqami:
            </Text>
            <PhoneViewer phoneNumber={user?.phoneNumber} />
            {/* <Text fw={500}>+{user?.phoneNumber}</Text> */}
          </Group>
        )}
        {user?.username && (
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Username:
            </Text>
            <Text fw={500} c="var(--mantine-color-anchor)">
              @{user?.username}
            </Text>
          </Group>
        )}
      </Box>
      <Button.Group>
        <Button
          variant="default"
          className="w-full"
          rightSection={<Eye size={14} />}
          onClick={() => handlerView("view")}
        >
          Ko'rish
        </Button>
        <Button
          variant="default"
          className="w-full"
          rightSection={<Pencil size={14} />}
          onClick={() => handlerView("edit")}
        >
          Tahrirlash
        </Button>
        <Button
          variant="default"
          className="w-full"
          rightSection={<Trash2 size={14} />}
          onClick={() => openModalRemove(user.id, user.fullName)}
        >
          O'chirish
        </Button>
      </Button.Group>
      {/* Moda View and Edit */}
      <Modal.Root opened={opened} onClose={onClose}>
        <Modal.Overlay backgroundOpacity={0.55} blur={3} />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              {modalType === "edit" ? "Tahrirlash" : "Ko'rish"}
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            {modalType === "edit" ? (
              <Box>
                <Box className="flex justify-center">
                  {user.pathFile ? (
                    <Avatar
                      size={"xl"}
                      className="shadow-md"
                      onClick={() =>
                        user.pathFile &&
                        dispatch({
                          type: OPEN_IMG_MODAL,
                          payload: user.pathFile,
                        })
                      }
                      // onClick={() => openUserProfile(user)}
                      alt="alexander"
                      src={`${"https://qarzim.uz/api"}/storage/files/${
                        user.pathFile
                      }`}
                    />
                  ) : (
                    <Avatar size={"xl"} />
                  )}
                </Box>
                <Flex justify={"center"} mt={10}>
                  <IsVerifed isVerifed={user.realUser} />
                </Flex>
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                  <Flex
                    gap="sm"
                    justify="flex-start"
                    align="flex-start"
                    direction="column"
                    wrap="wrap"
                  >
                    <TextInput
                      className="w-full"
                      label="To'liq ismi"
                      placeholder="Do'stingizni ismni kiriting"
                      {...form.getInputProps("fullName")}
                    />
                  </Flex>
                  <Group justify="flex-end" mt="md">
                    <Button type="submit">Saqlash</Button>
                  </Group>
                </form>
              </Box>
            ) : (
              <Box>
                <Box className="flex justify-center">
                  {user.pathFile ? (
                    <Avatar
                      size={"xl"}
                      className="shadow-md"
                      onClick={() =>
                        user.pathFile &&
                        dispatch({
                          type: OPEN_IMG_MODAL,
                          payload: user.pathFile,
                        })
                      }
                      // onClick={() => openUserProfile(user)}
                      alt="alexander"
                      src={`${"https://qarzim.uz/api"}/storage/files/${
                        user.pathFile
                      }`}
                    />
                  ) : (
                    <Avatar size={"xl"} />
                  )}
                </Box>
                <Flex justify={"center"} mt={10}>
                  <IsVerifed isVerifed={user.realUser} />
                </Flex>

                <Box mt="sm" mb="xs">
                  <Group justify="space-between">
                    <Text size="sm" c="dimmed">
                      Ismi:
                    </Text>
                    <Text fw={500}>{user?.fullName}</Text>
                  </Group>
                  {user?.phoneNumber && (
                    <Fragment>
                      <Divider my={"sm"} />

                      <Group justify="space-between">
                        <Text size="sm" c="dimmed">
                          Telefon raqami:
                        </Text>
                        <PhoneViewer phoneNumber={user?.phoneNumber} />

                        {/* <Text fw={500}>+{user?.phoneNumber}</Text> */}
                      </Group>
                    </Fragment>
                  )}
                  {user?.username && (
                    <Fragment>
                      <Divider my={"sm"} />
                      <Group justify="space-between">
                        <Text size="sm" c="dimmed">
                          Username:
                        </Text>
                        <Text fw={500} c="var(--mantine-color-anchor)">
                          @{user?.username}
                        </Text>
                      </Group>
                    </Fragment>
                  )}
                </Box>
                <Box className="px-2">
                  <Collapse in={isOpenQuote}>
                    <Blockquote
                      color="blue"
                      cite={`Ushbu foydalanuvchi Qarzim uz tizimida mavjud bo'lsa "Ro'yxatdan o'tgan" degan nishonga ega bo'ladi. Agar siz Qarzim uz tizimida mavjud bo'lmagan foydalanuvchi yaratsangiz toki u tizimdan ro'yxatdan o'tmaguniga qadar "Ro'yxatdan o'tmagan" degan nishon ostida bo'ladi`}
                      icon={<Info />}
                      mt="xl"
                      p={"lg"}
                    >
                      Eslatma:
                    </Blockquote>
                  </Collapse>
                </Box>
              </Box>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {/* Modal show image */}
      {/* <Modal.Root fullScreen opened={modalImage} onClose={closeModalImage}>
        <Modal.Overlay backgroundOpacity={0.55} blur={3} />
        <Modal.Content bg={"none"} onClick={closeModalImage}>
          <Modal.Body className="h-full flex items-center justify-center">
            <Flex
              justify={"center"}
              align={"center"}
              className="h-[90vh] overflow-hidden"
            >
              <X
                color="white"
                className="absolute right-4 top-4"
                size={20}
                onClick={closeModalImage}
              />

              <Image
                radius="md"
                w={"auto"}
                className="h-[80vh]"
                fit="contain"
                src={
                  user.pathFile
                    ? `${"https://qarzim.uz/api"}/storage/files/${user.pathFile.slice(
                        22,
                        user.pathFile.length
                      )}`
                    : ""
                }
              />
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root> */}
    </Card>
  );
};

export default FriendViewOrEdit;
