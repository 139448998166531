import { IRoute } from "../models/RoutesType/type";
import Report from "../pages/Report";
import Borrow from "../pages/Borrow";
import Lend from "../pages/Lend";
import Friends from "../pages/Friends";
import Test from "../pages/Test";
import Profile from "../pages/Profile";

export const routes: IRoute[] = [
  {
    name: "Report",
    path: "/report/:currentUserId",
    key: "report",
    component: Report,
  },
  {
    name: "Borrow",
    path: "/borrow/:currentUserId",
    key: "borrow",
    component: Borrow,
  },
  {
    name: "Lend",
    path: "/lend/:currentUserId",
    key: "lend",
    component: Lend,
  },
  {
    name: "Friends",
    path: "/friends/:currentUserId",
    key: "friends",
    component: Friends,
  },
  {
    name: "Profile",
    path: "/profile/:currentUserId",
    key: "profile",
    component: Profile,
  },
  {
    name: "Test",
    path: "/test",
    key: "test",
    component: Test,
  },
];
