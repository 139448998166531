import { PieChart } from "@mantine/charts";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  List,
  Loader,
  LoadingOverlay,
  SegmentedControl,
  Tabs,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { Fragment, useEffect, useState } from "react";
import NavTitle from "../../components/NavTitle";
import instance from "../../configs/axios";
import { MetaType } from "../../models/types";
import { PHOTO_PATH_URL } from "../../constants";

const carditems = [
  {
    name: "Dostonbek G’oyipov",
    price: "100 000",
    date: "1 Fevral 2024",
    value: 20,
  },
  {
    name: "Dostonbek G’oyipov",
    price: "100 000",
    date: "1 Fevral 2024",
    value: 40,
  },
  {
    name: "Dostonbek G’oyipov",
    price: "100 000",
    date: "1 Fevral 2024",
    value: 40,
  },
  {
    name: "Dostonbek G’oyipov",
    price: "100 000",
    date: "1 Fevral 2024",
    value: 40,
  },
  {
    name: "Dostonbek G’oyipov",
    price: "100 000",
    date: "1 Fevral 2024",
    value: 40,
  },
];

interface DataType {
  items: BorrowingData[];
  meta: MetaType;
}

interface BorrowingData {
  name: string;
  id: number;
  type: string;
  howMuch: number;
  username: string;
  createdAt: string;
  rePaymentDate: string;
  photoPath?: string;
  confirmation: boolean;
}

const centerClass: string =
  "absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]";

const Report = () => {
  const [items, setItems] = useState<any>(carditems);
  const [loading, setLoading] = useState<{
    general: boolean;
    borrow: boolean;
    borrowLoad: boolean;
    lend: boolean;
  }>({
    general: true,
    borrow: false,
    borrowLoad: false,
    lend: false,
  });
  const [isPercent, setIsPercent] = useState(false);
  const [trigger, setTrigger] = useState({
    general: false,
    borrow: false,
    lend: false,
  });
  const [chartData, setChartData] = useState([
    { name: "Qarz olganlar", value: 0, color: "red" },
    { name: "Qarz berganlar", value: 0, color: "indigo.6" },
  ]);
  const [borrowData, setBorrowData] = useState<BorrowingData[]>([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  // General data
  useEffect(() => {
    (async () => {
      try {
        setLoading((prevState) => ({
          ...prevState,
          general: true,
        }));
        const res = await instance.get(`/report/general`);
        const data: { borrowingAmount: number; lendingAmount: number } =
          res?.data?.data;

        if (data?.borrowingAmount || data?.lendingAmount) {
          chartData[0].value = data?.borrowingAmount;
          chartData[1].value = data?.lendingAmount;
          // console.log("🚀 ~ data:", data);
        }
      } catch (error) {
        console.log("🚀 ~ error:", error);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          general: false,
        }));
      }
    })();
  }, [trigger.general]);

  // Borrowing data
  async function getBorrowData(pageParam = page) {
    try {
      setLoading((prevState) => ({
        ...prevState,
        borrow: true,
      }));

      const params = {
        page: pageParam,
        size: 4,
      };

      const res = await instance.get(`/report/borrowing`, { params });
      const data: DataType = res?.data?.data;
      setPage((page) => page + 1);
      setHasNextPage(data.meta.hasNextPage);
      setBorrowData(data.items);
      console.log("🚀 ~ data:", data);
    } catch (error) {
      console.log("🚀 ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        borrow: false,
      }));
    }
  }

  const onChangeSegControl = (e: string) => {
    if (e === "percent") {
      setIsPercent(true);
    } else {
      setIsPercent(false);
    }
    console.log("🚀 ~ Report ~ e:", e);
  };

  const onChangeTabs = (e: any) => {
    if (e === "general") {
      setTrigger((prev) => ({
        ...prev,
        general: !prev.general,
      }));
    } else if (e === "borrow") {
      getBorrowData();
    } else if (e === "lend") {
      setTrigger((prev) => ({
        ...prev,
        lend: !prev.lend,
      }));
    }
  };

  function formatUzbekSum(number: any) {
    // Convert the number to a string
    let numberStr = number.toString();

    // Reverse the string
    let reversedStr = numberStr.split("").reverse().join("");

    // Add dots every three digits
    let formattedReversedStr = reversedStr.match(/.{1,3}/g).join(".");

    // Reverse the string back to the original order
    let formattedStr = formattedReversedStr.split("").reverse().join("");

    return formattedStr;
  }

  const loadMore = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        borrowLoad: true,
      }));

      const params = {
        page,
        size: 4,
      };

      const res = await instance.get(`/report/borrowing`, { params });
      const data: DataType = res?.data?.data;
      setPage((page) => page + 1);
      setHasNextPage(data.meta.hasNextPage);
      setBorrowData((prevData) => [...prevData, ...data.items]);
      console.log("🚀 ~ data:", data);
    } catch (error) {
      console.log("🚀 ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        borrowLoad: false,
      }));
    }
  };

  return (
    <div className="h-[100%]">
      <NavTitle title="Hisobotlarim"></NavTitle>
      {/* <Search /> */}

      <Tabs
        variant="default"
        radius="md"
        defaultValue="general"
        onChange={onChangeTabs}
      >
        <Tabs.List grow className="px-5 mb-3 sticky top-[60px] z-10 myFixedBg">
          <Tabs.Tab className="font-medium" value="general">
            Umumiy
          </Tabs.Tab>
          <Tabs.Tab className="font-medium" value="borrow">
            Olganlar
          </Tabs.Tab>
          <Tabs.Tab className="font-medium" value="lend">
            Berganlar
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="general">
          <Box mt={20} px={20}>
            {loading.general ? (
              <Loader color="blue" className={centerClass} />
            ) : chartData[0].value !== 0 || chartData[1].value !== 0 ? (
              <Fragment>
                <Flex justify={"center"}>
                  {/* <Text size="xl">No data</Text> */}
                  <PieChart
                    // withTooltip
                    // tooltipDataSource="segment"
                    withLabelsLine
                    labelsPosition="inside"
                    labelsType={"percent"}
                    withLabels
                    data={chartData}
                  />
                </Flex>
                {/* <SegmentedControl
                  fullWidth
                  color="blue"
                  onChange={onChangeSegControl}
                  withItemsBorders={false}
                  data={[
                    {
                      label: "So'mda",
                      value: "value",
                    },
                    {
                      label: "Foizda",
                      value: "percent",
                    },
                  ]}
                /> */}
                {/* <Divider /> */}
                <List
                  mt={20}
                  spacing="xs"
                  size="sm"
                  center
                  icon={
                    <ThemeIcon color="red" size={24} radius="xl"></ThemeIcon>
                  }
                >
                  <List.Item>
                    <Flex justify={"space-between"} className="w-full">
                      <Text fw={500}>Qarz olganlar:</Text>
                      <Text fw={700} c={"blue"}>
                        {formatUzbekSum(chartData[0].value)} so'm
                      </Text>
                    </Flex>
                  </List.Item>

                  <List.Item
                    icon={
                      <ThemeIcon
                        color="indigo.6"
                        size={24}
                        radius="xl"
                      ></ThemeIcon>
                    }
                  >
                    <Flex justify={"space-between"} className="w-full">
                      <Text fw={500}>Qarz berganlar:</Text>
                      <Text fw={700} c={"blue"}>
                        {formatUzbekSum(chartData[1].value)} so'm
                      </Text>
                    </Flex>
                  </List.Item>
                </List>
              </Fragment>
            ) : (
              <Text fw={500} className={centerClass}>
                Hozrda ma'lumotlar yo'q
              </Text>
            )}
          </Box>
        </Tabs.Panel>

        <Tabs.Panel value="borrow" className="h-[100%] space-y-3 mx-5">
          {loading.borrow ? (
            <Loader color="blue" className={centerClass} />
          ) : borrowData?.length ? (
            borrowData.map((item, inx) => (
              <Card
                shadow="sm"
                padding="sm"
                radius="md"
                withBorder
                key={item.id}
                mb={15}
              >
                <Group mb="xs">
                  <Avatar
                    src={
                      item?.photoPath
                        ? `${PHOTO_PATH_URL}/${item.photoPath}`
                        : ""
                    }
                  />
                  <Box className="text-left">
                    <Text fw={500}>{item?.name}</Text>
                    <Text span inherit c="var(--mantine-color-anchor)">
                      @{item?.username}
                    </Text>
                    {/* <Text fw={500}>@dostonbek02</Text> */}
                  </Box>
                  {/* <Badge color="blue">{item?.price}</Badge> */}
                </Group>

                {/* <Progress value={item.value} /> */}
                {/* <Box className="mt-3 text-left flex justify-between items-center">
                  <Text fw={500}>Keyingi to'lov sanasi:</Text>
                  <Text className="" size="md" c="dimmed">
                    {item.date}
                  </Text>
                </Box> */}
                <Divider />
                <Box className="mt-2 text-left flex justify-between items-center">
                  <Text fw={500}>Summa:</Text>
                  {/* <Badge color="blue" variant="dot" mr={10}>
                    Bo'lib to'lash
                  </Badge> */}
                  <Badge color="blue" variant="dot" fw={700}>
                    {formatUzbekSum(item?.howMuch)} so'm
                  </Badge>
                </Box>
                {/* <Button.Group className="mt-3">
                  <Button variant="default" fullWidth>
                    View
                  </Button>
                  <Button variant="default" fullWidth>
                    Edit
                  </Button>
                  <Button variant="default" fullWidth>
                    Delete
                  </Button>
                </Button.Group> */}
              </Card>
            ))
          ) : (
            <Text fw={500} className={centerClass}>
              Hozrda ma'lumotlar yo'q
            </Text>
          )}

          {hasNextPage ? (
            <Box>
              <Button
                onClick={loadMore}
                loading={loading.borrowLoad}
                loaderProps={{ type: "dots" }}
              >
                Yana yuklash
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Tabs.Panel>

        <Tabs.Panel value="lend">
          <Box>
            <Text fw={500} className={centerClass}>
              Hozrda ma'lumotlar yo'q
            </Text>
          </Box>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Report;
