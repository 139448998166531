import React, { ReactNode } from "react";
import { Anchor, Paper } from "@mantine/core";
import { Sticker } from "lucide-react";

interface ResultPaperProps {
  title: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  //   content?: ReactNode;
}

const ResultPaper = ({ title, subtitle, icon }: ResultPaperProps) => {
  return (
    <Paper className="p-6 centerAbsolute bg-transparent">
      <div className="text-center">
        <div className="flex justify-center">
          {icon ? icon : <Sticker size={40} />}
        </div>
        <h2 className="text-xl font-semibold">{title}</h2>
        {subtitle && subtitle}

        {/* {content && <div className="mt-4">{content}</div>} */}
      </div>
    </Paper>
  );
};

export default ResultPaper;
