import { Elements } from "./types";

const elements: Elements[] = [
  { id: 1, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 2, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 3, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 4, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 5, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 6, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 7, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 8, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 9, date: "1 Fev 2024", price: "100.000 so'm" },
  { id: 10, date: "1 Fev 2024", price: "100.000 so'm" },
];

export { elements };
