import { motion, useAnimation } from "framer-motion";
import { MyFriend, ResFriend } from "../../pages/Friends";
import { useInView } from "react-intersection-observer";
import { useContext, useEffect } from "react";
import FriendCard from "../FriendViewOrEdit";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Group,
  Image,
  Text,
} from "@mantine/core";
import { Trash2, UserPlus } from "lucide-react";
import { OPEN_IMG_MODAL } from "../../context/actionTypes";
import { Context } from "../../context";
import PhoneViewer from "../PhoneViewer";

type PropsType = {
  user: ResFriend;
  handlerAddAndRemoveUser: any;
  addingUser: number | null;
};

const PostItemGlobal = (props: PropsType) => {
  const { user, handlerAddAndRemoveUser, addingUser } = props;
  const controls = useAnimation();
  const { dispatch } = useContext(Context);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    }
  }, [controls, inView]);

  const item = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
  };
  return (
    <motion.div
      variants={item}
      initial="hidden"
      animate={controls}
      ref={ref}
      // className="overflow-hidden"
    >
      <Card shadow="sm" padding="sm" radius="md" withBorder className="mb-2">
        <Card.Section pt={"sm"}>
          <Flex justify={"center"}>
            <Avatar
              className="shadow-md"
              onClick={() =>
                user.pathFile &&
                dispatch({
                  type: OPEN_IMG_MODAL,
                  payload: user.pathFile,
                })
              }
              size={"xl"}
              src={
                user.pathFile
                  ? `${"https://qarzim.uz/api"}/storage/files/${user.pathFile}`
                  : ""
              }
            />
          </Flex>
          {/* <Image
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
          height={160}
          alt="Norway"
        /> */}
        </Card.Section>

        <Box mt="sm" mb="xs">
          <Group justify="space-between">
            <Text size="sm" c="dimmed">
              Ismi:
            </Text>
            <Text fw={500}>{user?.fullName}</Text>
          </Group>
          {user?.phoneNumber && (
            <Group justify="space-between">
              <Text size="sm" c="dimmed">
                Telefon raqami:
              </Text>
              <PhoneViewer phoneNumber={user?.phoneNumber} />
              {/* <Text fw={500}>+{user?.phoneNumber}</Text> */}
            </Group>
          )}
          {user?.username && (
            <Group justify="space-between">
              <Text size="sm" c="dimmed">
                Username:
              </Text>
              <Text fw={500} c="var(--mantine-color-anchor)">
                @{user?.username}
              </Text>
            </Group>
          )}
        </Box>

        <Button
          color={user.friend ? "red" : undefined}
          variant="light"
          loading={addingUser === user.userId}
          fullWidth
          mt="md"
          onClick={() => handlerAddAndRemoveUser(user)}
          radius="md"
          // rightSection={<UserPlus size={14} />}
          leftSection={
            !user.friend ? <UserPlus size={14} /> : <Trash2 size={14} />
          }
        >
          {!user.friend ? "Do'stlarimga qo'shish" : "O'chirish"}
        </Button>
      </Card>
      {/* <FriendCard user={user} /> */}
      {/* <Box>
          <Paper shadow="xs" radius="md" withBorder p="sm" className="mb-2">
            <div
              role="button"
              className="flex justify-between items-center w-full leading-tight transition-all rounded-lg outline-none text-start bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50  text-blue-gray-900 bg-blue-gray-50 bg-opacity-80 text-blue-gray-900"
            >
              <div className="flex items-center">
                <div className="grid mr-4 place-items-center">
                  {user.pathFile ? (
                    <img
                      alt="alexander"
                      src={`${"https://qarzim.uz/api"}/storage/files/${user.pathFile.slice(
                        22,
                        user.pathFile.length
                      )}`}
                      className="relative inline-block h-12 w-12 !rounded-full  object-cover object-center shadow-md"
                    />
                  ) : (
                    <Avatar className="w-12 h-12" />
                  )}
                </div>
                <div>
                  <Text className="truncate w-[100px]">{user.fullName} </Text>
                  <Text
                    className="mt-0 truncate w-[100px]"
                    inherit
                    c="var(--mantine-color-anchor)"
                  >
                    @{user.username}
                  </Text>
                </div>
              </div>
              <div>
                <EditFriendButton user={user} />
                <Button
                  loading={removingLoading === user.id}
                  onClick={() => openModalRemove(user.id, user.fullName)}
                  variant="light"
                  color="red"
                >
                  <Trash2 size={16} />
                </Button>
              </div>
            </div>
          </Paper>
        </Box> */}
    </motion.div>
  );
};
export default PostItemGlobal;
