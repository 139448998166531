import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ACCESS_TOKEN } from "../constants";
const baseAPI = process.env.REACT_APP_BASE_API;
const instance = axios.create();
const token: string | null = window.location.href.split("/")?.[4];

instance.defaults.baseURL = baseAPI;
// console.log("window: ");

const onFulfilledReq = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers = {
    ...config.headers,
    ...(token && {
      Authorization: "Bearer " + token,
    }),
  };

  return config;
};

const onRejectedReq = (error: AxiosError) => {
  return Promise.reject(error);
};

const onFulfilledRes = (response: AxiosResponse) => {
  return response;
};

const onRejectedRes = (error: AxiosError) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(onFulfilledReq as any, onRejectedReq);
instance.interceptors.response.use(onFulfilledRes, onRejectedRes);

export default instance;
